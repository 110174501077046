<div class="forgotpassword-container">
  <div class="forgotpassword-form-container">
    <mat-card>  
      <div>
        <h3>{{ 'forgot_password_title' | translate }}</h3>
        <p>{{ 'forgot_password_text' | translate }}</p>
      </div>
      <mat-form-field>
        <input matInput placeholder="Email">
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="openDialog()">{{ 'confirm' | translate }}</button>
      <button mat-button color="primary" (click)="closeDialog()">{{ 'close' | translate }}</button>
    </mat-card>
  </div>
</div>
