import { OnInit } from '@angular/core';
import { IMqttMessage, IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { first } from 'rxjs/operators';
import { CmdElencoComandi } from 'src/app/objects/cmdElencoComandi';
import { MacMacchine } from 'src/app/objects/macMacchine';
import { RlRealTime } from 'src/app/objects/rlRealTime';
import { SetSetpointParameters } from 'src/app/objects/setSetpointParameters';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CommandService } from 'src/app/services/command.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfigUtil } from 'src/app/utils/configUtils';


export class RealtimeLcdComponent implements OnInit {

  machineSessioned: MacMacchine = new MacMacchine();
  listSectors: Number[] = [];
  ifFirstTime = true;
  realtimeObj: RlRealTime = new RlRealTime();
  realTimeEnableSector = 0;
  realTimeEnableSectorKey = 'realTimeEnableSectorKey';
  commandsListString: CmdElencoComandi[] = [];
 
  constructor(
    protected mqttService: MqttService,
    protected sessionService: SessionService,
    protected snackBarService: SnackbarService,
    protected commandService:CommandService
    ) {
  }

  ngOnInit(): void {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
    console.log(this.machineSessioned);

      const topicRealTimeSubscribed = 'Frigomat/RealTime/' + this.machineSessioned.maC_Numero_Serie_MS;
      const topicWiManagerSubscribed = 'Frigomat/WiManager/' + this.machineSessioned.maC_Numero_Serie_MS;
      const topicReturnSubscribed = 'Frigomat/Return/' + this.machineSessioned.maC_Numero_Serie_MS;

      console.log('topicRealTimeSubscribed: ', topicRealTimeSubscribed);

      this.mqttService.observe(topicRealTimeSubscribed).subscribe((message: IMqttMessage) => {
        console.log('Arrived topic: REALTIME');

          console.log('MESSAGE', message.payload.toString());
          const tmpMessage = message.payload.toString();
          this.getResponseMQTT(tmpMessage);
      });

      this.mqttService.observe(topicWiManagerSubscribed).subscribe((message: IMqttMessage) => {
        console.log('Arrived topic: WIMANAGER');

        if (this.ifFirstTime === false) {
          // this.getResponseMQTT(message.payload.toString());
          this.realTimeEnableSector = +localStorage.getItem(this.realTimeEnableSectorKey);
          if (this.realTimeEnableSector !== 0) {
            this.startRealTime(this.realTimeEnableSector);
          }
        }

        this.ifFirstTime = false;
      });

        // check on return command
      this.mqttService.observe(topicReturnSubscribed).subscribe((message: IMqttMessage) => {
        console.log('Arrived topic: RETURN');
        console.log('Message receive RETURN', message);

        const translated = this.base64toHEX(message.payload);
        console.log('TRANSLATED RETURN: ', translated);

        const resultFirstByte = parseInt(translated.substr(0, 2), 8);
        console.log('resultFirstByte RETURN: ', resultFirstByte);

        if(resultFirstByte === 1){
          this.snackBarService.showSnackBar('Comando ricevuto dalla macchia con successo.');
        } else {
          this.snackBarService.showSnackBar('Il comando è stato ricevuto ma non processato. Stato macchina incompatibile.')
        }
     });
     
 

    this.getCommandsList();
    this.getSectors();

    this.applyConversion(65447);
  }

  getSectors() {

    if(this.machineSessioned.maC_ModelloSx >0){
      this.listSectors.push(1);
    }

    if(this.machineSessioned.maC_ModelloDx > 0){
      this.listSectors.push(2);
    }

    if(this.machineSessioned.maC_ModelloCen > 0){
      this.listSectors.push(3);
    }
  }

  startRealTime(sxdx: number) {
    console.log('-----------------------');
    console.log('Settore: ', sxdx);

    localStorage.setItem(this.realTimeEnableSectorKey, sxdx.toString());

    const u8 = new Uint8Array([1,1,0,0,sxdx,0,0,0,0,0,0,0]);
    var app=this;
    this.sendPortal(u8,function(){
      console.log('sended startRealTime');
      app.realTimeEnableSector = sxdx;
    },function(err){console.error(err)});
  }

  sendCommandRealTime(sxdx: number, numCommand: number) {
    console.log('-----------------------');
    console.log('settore: ', sxdx);
    console.log('stop - numCommand: ', numCommand);

    const u8 = new Uint8Array([1,1,0,0,sxdx,numCommand,0,0,0,0,0,0]);

    var app= this;
    this.sendPortal(u8,function(){
      console.log('sended startRealTime');
      app.snackBarService.showSnackBar('Invio comando in corso..');
    },function(err){console.error(err);app.snackBarService.showSnackBar('Errore invio comando.');});
  }

  stopRealTime() {
    console.log('------------------------');
    const u8 = new Uint8Array([1,0,0,0,0,0,0,0,0,0,0,0]);

    var app= this;
    this.sendPortal(u8,function(){
      app.realTimeEnableSector = 0;
      app.realtimeObj.sx_dx = 0;
      console.log('sended stop realtime');
    },function(err){console.error(err)});
  }

  getResponseMQTT(msg: string) {

    console.log('TMP: ', msg);

    try {
      const translated = this.base64toHEX(msg);
      console.log('TRANSLATED: ', translated);

      this.realtimeObj = new RlRealTime();

      this.realtimeObj.sx_dx = parseInt(translated.substr(0, 2), 16);
      this.realtimeObj.stato = parseInt(translated.substr(2, 2), 16);
      this.realtimeObj.allarmi = parseInt(translated.substr(10, 10), 16);
      this.realtimeObj.tensione = parseInt(translated.substr(20, 4), 16);
      this.realtimeObj.assorbimento = parseInt(translated.substr(24, 4), 16);
      this.realtimeObj.sfasamento = parseInt(translated.substr(28, 4), 16);
      this.realtimeObj.potenza = parseInt(translated.substr(32, 4), 16);
      this.realtimeObj.consistenza = parseInt(translated.substr(36, 4), 16);
      this.realtimeObj.temp_vasca = this.applyConversion(parseInt(translated.substr(40, 4), 16)); // FFA7 | -8.9 convert to unsegned
      this.realtimeObj.temp_cts = this.applyConversion(parseInt(translated.substr(44, 4), 16)); // FFC0 | -
      this.realtimeObj.pt100_3 = parseInt(translated.substr(48, 4), 16);
      this.realtimeObj.lettco_1 = parseInt(translated.substr(52, 4), 16);
      this.realtimeObj.lettco_2 = parseInt(translated.substr(56, 4), 16);
      this.realtimeObj.ingressi = parseInt(translated.substr(60, 2), 16);
      this.realtimeObj.uscite = parseInt(translated.substr(62, 2), 16);

      this.realTimeEnableSector = this.realtimeObj.sx_dx;

      if (this.realtimeObj.sx_dx !== 49 && this.realtimeObj.sx_dx !== 0) {
        this.startRealTime(this.realtimeObj.sx_dx);
      }

      console.log('REALTIME OBJ: ', this.realtimeObj);

    } catch (error) {

    }

  }

  sendPortal(u8:Uint8Array,callbackFunction,callbackError){
    const decoder = new TextDecoder('utf8');
    const b64encoded = btoa(decoder.decode(u8));

    this.mqttService.publish('Frigomat/Portal/' + this.machineSessioned.maC_Numero_Serie_MS, b64encoded)
    .subscribe(res => {
      callbackFunction();
    }, err => {
      callbackError(err);
    });
  }

  applyConversion(val: number): number {
    // 65447  > 32767 SI > tolgo 65536 | se ho una cifra, metto lo 0 davanti, se ho 2 cifre moltiplico x 100 (esempio 2 x 100 = 200 = 20.0 )
    let toReturn = '';
    if (val > 32767) { val = val - 65536; }

    const lenghtVal = val.toString().length;

    if (val.toString().includes('-')) {
      if (lenghtVal <= 2) {
        toReturn = val.toString()[0] + '0.' + val.toString()[1];
      }

      // debugger;
      if (lenghtVal > 2 && lenghtVal < 4) {
        toReturn = val.toString()[0] + val.toString()[1] + '.' + val.toString()[2];
      }

      if (lenghtVal === 4) {
        toReturn = val.toString()[0] + val.toString()[1] + val.toString()[2] + '.' + val.toString()[3];
      }

      if (lenghtVal > 4) {
        toReturn = val.toString()[0] + val.toString()[1] + val.toString()[2] + val.toString()[3] + '.' + val.toString()[4];
      }
    } else {
      if (lenghtVal <= 1) {
        toReturn = '0.' + val.toString()[0];
      }
      // debugger;
      if (lenghtVal > 1 && lenghtVal < 3) {
        toReturn = val.toString()[0] + '.' + val.toString()[1];
      }

      if (lenghtVal === 3) {
        toReturn = val.toString()[0] + val.toString()[1] + '.' + val.toString()[2];
      }

      if (lenghtVal > 3) {
        toReturn = val.toString()[0] + val.toString()[1] + val.toString()[2] + '.' + val.toString()[3];
      }
    }
    return Number(toReturn);
  }

  public ngOnDestroy() {
  }

  base64toHEX(base64) {
    const raw = atob(base64);
    let HEX = '';

    for (let i = 0; i < raw.length; i++ ) {
      const Hex = raw.charCodeAt(i).toString(16);
      HEX += (Hex.length === 2 ? Hex : '0' + Hex);
    }

    return HEX.toUpperCase();
  }

  translateStatus(status: number): string {
    console.log('translate status');
    try {
      const getted = this.commandsListString.find(x => x.cmD_IdComando === status);
      return getted.cmD_Descrizione;
    } catch (error) {
      return '';
    }
  }

  getCommandsList() {
    this.commandService.listObservable.subscribe(res => {
      this.commandsListString = res;
    });
  }

}
