import { Component } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { CommandService } from 'src/app/services/command.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { RealtimeLcdComponent } from '../realtime-lcd/realtime-lcd.component';

@Component({
  selector: 'app-realtime-peb',
  templateUrl: './realtime-peb.component.html',
  styleUrls: ['./realtime-peb.component.scss']
})
export class RealtimePebComponent extends RealtimeLcdComponent {

  constructor(
    protected mqttService: MqttService,
    protected sessionService: SessionService,
    protected snackBarService: SnackbarService,
    protected commandService:CommandService
    ) {
      super(mqttService,sessionService,snackBarService,commandService);
    }

    stopRealTime() {
      console.log('------------------------');
      const u8 = new Uint8Array([1,0,0,0,0,0,0,0,0,0,0,0]);

      var app =this;
  
      this.sendPortal(u8,function(){
        app.realTimeEnableSector = 0;
        app.realtimeObj.sx_dx = 0;
        app.realtimeObj.temp_vasca = undefined;
        app.realtimeObj.temp_cts = undefined;
        console.log('sended stop realtime');
      },function(err){console.error(err)});
    }
}