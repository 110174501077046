export class RlRealTime {
    sx_dx: number;
    stato: number;
    allarmi: number;
    tensione: number;
    assorbimento: number;
    sfasamento: number;
    potenza: number;
    consistenza: number;
    temp_vasca: number;
    temp_cts: number;
    pt100_3: number;
    lettco_1: number;
    lettco_2: number;
    ingressi: number;
    uscite: number;
  }