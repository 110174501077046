import { Injectable, Optional, Inject, InjectionToken } from '@angular/core';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { IModelObj } from '../objects/iModelObj';

export const API_NAME = new InjectionToken<string>('API_NAME');

@Injectable({
  providedIn: 'root'
})

export class GenericCrudService<Gen extends IModelObj,ID> {
  endpoint: string;

  public data:Gen[]=[];
  public list: BehaviorSubject<Gen[]> = new BehaviorSubject<Gen[]>(this.data);
  public listObservable: Observable<Gen[]>;
 // private loading = false;

  constructor(
    protected http: HttpClient,
    protected appConfigService: AppConfigService,
    @Inject(API_NAME) @Optional() public apiName?: String,
  ) {
    this.endpoint = appConfigService.apiBaseUrl;
    this.listObservable = this.list.asObservable();
  }

  load() {
   // if (!this.loading) {

    this.read().subscribe((data) => {
        this.list.next(data);
      }, () => {  });
    //}
  }

  // CREATE
  create(objToCreate: Gen): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${this.apiName}`, objToCreate);
  }


  // READ
  read(): Observable<Gen[]> {
    //this.loading = true;

    console.log(this.apiName);
    return this.http.get<Gen[]>(`${this.endpoint}/${this.apiName}`);
  }

  // UPDATE
  update(objToUpdate: Gen): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/${this.apiName}`, objToUpdate);
    //return this.http.patch<any>(`${this.endpoint}/${this.apiName}`, objToUpdate);
  }

  // DELETE
  delete(objToDeleteKey:string, objToDeleteId: ID) : Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/${this.apiName}?${objToDeleteKey}=${objToDeleteId}`);
  }

}
