import { ModElencoModelli } from "./modElencoModelli";

export class DtoNotificationModel {
  activeModel: boolean;
  model: ModElencoModelli;
  types: DtoNotificationType[];
}

export class DtoNotificationType {
  activeType: boolean;
  name: number;
  index: number;
  list: DtoNotificationElement[];
}

export class DtoNotificationElement {
  id: number;
  description: string;
  active: boolean;
}

export const TypesNames=[
  'none',
  'alarms_all',
  'commands_all',
  'parameters',
  'productions'
]

