import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarService } from "../services/snackbar.service";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FirFirmware } from "../objects/firFirmware";
import { FirmwareService } from "../services/firmware.service";
import moment from "moment";
import { DeleteModalComponent } from "../modals/delete-modal/delete-modal.component";

@Component({
  selector: "app-firmwares",
  templateUrl: "./firmwares.component.html",
  styleUrls: ["./firmwares.component.scss"],
})
export class FirmwaresComponent implements OnInit {
  displayedColumns: string[] = [
    "fiR_Name",
    "fiR_Description",
    "fiR_Version",
    "fiR_ReleaseDate",
    "fiR_MachineType",
    "actions",
  ];
  dataSource: MatTableDataSource<FirFirmware> =
    new MatTableDataSource<FirFirmware>();

  loading = true;
  form: FormGroup;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  srcResult: any;
  selectedFile: any;
  isExpanded: boolean = false;

  constructor(
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private firmwareService: FirmwareService,
    protected fb: FormBuilder
  ) {}

  ngOnInit() {
    this.firmwareService.listObservable.subscribe(
      (data: any[]) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.sort.direction = "asc";
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      (error) => console.log("error"),
      () => console.log("completeLoad")
    );
    this.loading = true;
    this.firmwareService.load();

    this.form = this.fb.group({
      fir_Name: ["", Validators.required],
      fir_Description: [""],
      fir_Version: ["", Validators.required],
      fir_ReleaseDate: ["", Validators.required],
      fir_MachineType: ["", Validators.required],
    });
  }
  initForm(): void {
  this.form.controls['fir_MachineType'].setValue(null)
  this.form.controls['fir_Name'].setValue('')
   this.form.controls['fir_Description'].setValue('')
   this.form.controls['fir_Version'].setValue('')
   this.form.controls['fir_ReleaseDate'].setValue(null)
 this.selectedFile = ''
 this.srcResult = ''
  }

  addFirmware() {
    let firmwareTemp: FirFirmware = {
      id: undefined,
      fiR_Id: undefined,
      fiR_MOD_Id: this.form.get('fir_MachineType').value,
      fiR_Name: this.form.get('fir_Name').value,
      fiR_Description: this.form.get('fir_Description').value,
      fiR_Version: this.form.get('fir_Version').value,
      fiR_ReleaseDate: moment(this.form.get('fir_ReleaseDate').value).utc(true).format('yyyy-MM-DD')+'T00:00:00',
      fiR_File: this.selectedFile,
      fiR_Link: undefined,
      fiR_MOD: undefined,
    };

    this.firmwareService.createFile(firmwareTemp).subscribe(
      (x) => {
        this.snackBarService.showSnackBar(
          this.translateService.instant("operation_successfully")
        );
        this.loading = true;
        this.firmwareService.load();
        this.isExpanded = false;
        this.initForm();
      },
      (err) => {
        this.snackBarService.showSnackBar(
          this.translateService.instant("error") +
            ":" +
            this.translateService.instant(err.error)
        );

        console.log("Error add firmware: ", err);
      }
    );

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onFileSelected(event:any) {
    const inputNode: any = document.querySelector("#file");
    this.selectedFile = event.target.files[0] ?? null;
    this.form.controls['fir_Name'].setValue(this.selectedFile.name);
    console.log(this.selectedFile);


    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
        console.log(this.srcResult)

      };

      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  downloadFile(element: FirFirmware): void {
    this.firmwareService.download(element.fiR_Id).subscribe((blob) => {
      const a = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = element.fiR_Name;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  deleteFirmware(firmware: FirFirmware): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      data: {
        service: this.firmwareService,
        key: 'FIR_Id',
        id: firmware.fiR_Id
      }});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
