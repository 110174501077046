import { Component } from '@angular/core';
import { MqttService } from 'ngx-mqtt';
import { CommandService } from 'src/app/services/command.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { RealtimeLcdComponent } from '../realtime-lcd/realtime-lcd.component';


@Component({
  selector: 'app-realtime-chef',
  templateUrl: './realtime-chef.component.html',
  styleUrls: ['./realtime-chef.component.scss']
})
export class RealtimeChefComponent extends RealtimeLcdComponent {

  constructor(
    protected mqttService: MqttService,
    protected sessionService: SessionService,
    protected snackBarService: SnackbarService,
    protected commandService:CommandService,
    ) {
      super(mqttService,sessionService,snackBarService,commandService);

      debugger
      console.log(this.realtimeObj.sx_dx);
    }
}
