import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmaMachineList } from '../objects/emaMachineList';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class EmaService  extends GenericCrudService<EmaMachineList,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"machineList");
   this.load();
 }
}
