import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { SessionService } from '../services/session.service';
import { ConfigUtil } from '../utils/configUtils';
import { HttpClient } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MacMacchine } from '../objects/macMacchine';
import { Router } from '@angular/router';
import { EmaMachineList } from '../objects/emaMachineList';
import { map, switchMap, tap, toArray, delay } from 'rxjs/operators';
import { AppConfigService } from '../services/app-config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {

  testPrintTraduzione = '';
  machineSession: MacMacchine = new MacMacchine();
  machinesModelsList: EmaMachineList[] = [];

  displayedColumns: string[] = ['maC_Matricola', 'maC_Numero_Serie_MS', 'maC_ModelString', 'address', 'select'];
  dataSourceMachine:MatTableDataSource<MacMacchine>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private loadingService: LoaderService,
    private sessionService: SessionService,
    private httpClient: HttpClient,
    private route: Router,
    private appConfigService:AppConfigService
  ) {

    this.machineSession = this.sessionService.currentMachineValue;
    this.getMachinesName();
    this.getMachines();
   }

  ngOnInit() {
  }

  getMachinesName() {
    this.httpClient.get<EmaMachineList[]>(this.appConfigService.apiBaseUrl + '/machineList')
    .subscribe(res => {
      this.machinesModelsList = res;
    });
  }

  getMachines() {
    // this.showLoading(true);
    // debugger
    // console.log('sended user: ', this.userSession.userSessioned);
    this.httpClient.get<MacMacchine[]>(this.appConfigService.apiBaseUrl + '/machine')
    .pipe(
      delay(200),
      switchMap(x => x.filter(y => y.maC_Matricola !== null || y.maC_Matricola !== '')),
      tap(x => x.maC_ModelString = this.getModelString(x.maC_Macchina)),
      map(x => x),
      toArray(),
      tap(_ => console.log('aa'))
    )
    .subscribe(res => {
      this.dataSourceMachine = new MatTableDataSource(res);
      this.dataSourceMachine.paginator = this.paginator;
      this.dataSourceMachine.sort = this.sort;
    });
  }



  applyFilter(filterValue: string) {
    this.dataSourceMachine.filter = filterValue.trim().toLowerCase();
  }

  selectMachine(machine: MacMacchine) {
    this.sessionService.removeGenericSession(ConfigUtil.machineSessioned);
    this.sessionService.setGenericSession(machine, ConfigUtil.machineSessioned);
    console.log('selected: ', machine);
    this.sessionService.setMachineSession(machine);
  }

  getModelString(idModel: number) {
    const tmp = this.machinesModelsList.find(x => x.emA_Id === idModel);
    try {
      return tmp.emA_Descrizione;
    } catch (error) {
      return '-';
    }
  }

  ngOnDestroy(): void {
    // destroy component on destroy
    console.log('close dashboard');
    this.route.dispose();
  }
}
