import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as languagesJson from '../../assets/i18n/languages.json';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages;

  constructor(
    private translate: TranslateService
  ) {
    this.languages = (languagesJson  as  any).default;
   }

  setLanguage(lng: string){
    const lngDefault = localStorage.getItem('lngDefault');
    if(lng === undefined || lng === null){
      lng = 'it';
    }
    console.log('Language set: ', lng)
    lng = (lng === undefined || lng === null) ? 'it' : lng;
    localStorage.setItem('lngDefault', lng);
    this.translate.use(lng);
  }

  setDefaultLanguageAtStart(){
    const lngDefault = localStorage.getItem('lngDefault');
    console.log('lngDefault: ', lngDefault);
    if(lngDefault === null || lngDefault === undefined){
      this.translate.setDefaultLang('it');
    } else {
      this.translate.setDefaultLang(lngDefault);
    }

    console.log('lngDefault: ', lngDefault);
  }

  getLanguages(){
    return this.languages;
  }
}
