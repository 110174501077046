<div class="main-container">
  <div class="inside-main-container">
      <mat-accordion >
        <mat-expansion-panel class="mat-elevation-z8">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>{{ 'add' | translate }} {{ 'user' | translate }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h6>{{ 'add_user_string' | translate }}</h6>
          <div class="example-container">
            <form [formGroup]="form" id="ngForm" (ngSubmit)="addUser()">
              <mat-form-field>
                <input matInput placeholder="Email" required formControlName="ute_usernameCtrl">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="Password" required formControlName="ute_passwordCtrl">
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="{{ 'description' | translate}} / {{ 'factory' | translate}}" required formControlName="ute_descriptionCtrl">
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="{{ 'type' | translate}}" required formControlName="ute_levelCtrl">
                  <mat-option [value]="4">{{ 'customer' | translate }}</mat-option>
                  <mat-option [value]="2">{{ 'dealer' | translate }}</mat-option>
                  <mat-option [value]="3">{{ 'technician' | translate }}</mat-option>
                  <mat-option [value]="1">{{ 'administrator' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="{{ 'enabled' | translate}}" required formControlName="ute_enabledCtrl">
                  <mat-option [value]="0">{{ 'disabled' | translate }}</mat-option>
                  <mat-option [value]="1">{{ 'enabled' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-raised-button color="primary" [disabled]="!form.valid" form="ngForm" type="submit">{{ 'add' | translate }} {{ 'user' | translate }}</button>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    <br/>
    <mat-card class="mat-elevation-z8">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate}}...">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" matSort >

          <ng-container matColumnDef="utE_Nome">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'user' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.utE_Nome}} </td>
          </ng-container>

          <ng-container matColumnDef="utE_Descrizione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'description' | translate }} / {{ 'factory' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.utE_Descrizione}} </td>
          </ng-container>

          <ng-container matColumnDef="password">
            <th mat-header-cell *matHeaderCellDef> {{ 'password' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.utE_Password}} </td>
          </ng-container>

          <ng-container matColumnDef="utE_Livello">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'type' | translate }} </th>
            <td mat-cell *matCellDef="let element">{{element.utE_Livello | role}}</td>
          </ng-container>

          <ng-container matColumnDef="utE_Abilitato">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'enabled' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <label *ngIf="element.utE_Abilitato === 1" style="color: #3F51B5;">{{ 'enabled' | translate }}</label>
              <label *ngIf="element.utE_Abilitato === 0" style="color: orange;">{{ 'disabled' | translate }}</label>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button *ngIf="element.utE_Abilitato !== -1" (click)="modifyUser(element)" color="primary">{{ 'edit' | translate }}</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1000]" showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>
</div>
