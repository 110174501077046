import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'softStatusIcon'
})
export class SoftStatusIconPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return null;

    /*<span *ngIf="(realtimeObj.sx_dx === setpoint.seT_SxDx)" [ngSwitch]="realtimeObj.stato">
              <div *ngSwitchCase="[1, 2, 4].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
                <mat-icon>play_arrow</mat-icon>
              </div>
              <div *ngSwitchCase="[16, 32].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
                <mat-icon class="rotate-8-s">refresh</mat-icon>
              </div>
              <div *ngSwitchCase="[8].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
                <mat-icon>CN</mat-icon>
              </div>
              <div *ngSwitchCase="[0].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
                <mat-icon>stop</mat-icon>
              </div>
              <div *ngSwitchDefault>
                <mat-icon>stop</mat-icon>
              </div>
              </span>*/
  }

}
