import { Component, EventEmitter, Input, OnInit, Output, PipeTransform } from '@angular/core';
import { PipeUnTransform } from '../utils/pipes/pipe-untransform';

@Component({
  selector: 'app-buttons-editing-parameters',
  templateUrl: './buttons-editing-parameters.component.html',
  styleUrls: ['./buttons-editing-parameters.component.scss']
})
export class ButtonsEditingParametersComponent implements OnInit {
  
  @Input() ctrl: any = null;
  @Input() idx: number = 0;
  @Input() multiplierValue: number = 1;
  @Input() initialVal: number = 1;
  @Input() step: number = 1;
  @Input() displayPipe:PipeUnTransform=undefined;
  @Input() displayPipeParam:any;
  @Input() show:boolean=false;
  @Input() type: string="Number";
  @Output() newValue = new EventEmitter();

  editing: boolean = false;
  
  constructor(
  ) {  }

  ngOnInit() {
  }

  multiplierValueToPrecision() {
    return Math.log10(this.multiplierValue);
  }

  save() {
    if (this.ctrl.checkValidity!=undefined && !this.ctrl.checkValidity()) {
      this.ctrl.reportValidity();
      return;
    }
    let value ='';
    if(this.type=="String"){
      value =''+ this.displayPipe.untransform(this.ctrl.value,this.displayPipeParam);
    }
    else{
      value = this.ctrl.value != undefined ? ( Number(this.ctrl.value)/(this.step/this.multiplierValue)).toFixed(0) : (this.ctrl.checked ? '1' : '0');
      
      if(this.displayPipe!=undefined){
        value =''+ this.displayPipe.untransform(value,this.displayPipeParam);
      }
    }
    
    this.newValue.emit({"value":this.ctrl.value != undefined ? value : (this.ctrl.checked ? 1 : 0),"index":this.idx});
    this.editing = false;
    this.ctrl.disabled = true;
  }
  
  cancel() {
    //this.save();
    let value = this.initialVal != undefined ? ( this.initialVal/(this.step/this.multiplierValue)).toFixed(0) : (this.ctrl.checked ? '1' : '0');
    this.ctrl.value = (Number(value)*this.step)/this.multiplierValue;

    if(this.displayPipe!=undefined){
      value =''+ this.displayPipe.untransform(value,this.displayPipeParam);
    }
    
    this.ctrl.checked=value=="1";

    this.newValue.emit({"value":this.ctrl.value != undefined ? value : (this.ctrl.checked ? 1 : 0),"index":this.idx});
    
    this.editing = false;
    this.ctrl.disabled = true;
  }

   edit() {
    this.editing = true;
    this.ctrl.disabled = false;
    this.ctrl.focus();
  }
}
