<div class="about-container">
  <div>
    <img src="../../../assets/img/logo.png">
  </div>
  <div>
    <b>Frigomat S.r.l.</b><br>
    Via I Maggio 28<br>
    Guardamiglio (LO) Italia<br>
    +39 0377 415011<br>
    frigomat@frigomat.com
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="closeDialog()">{{'close' | translate}}</button>
  </div>
</div>
