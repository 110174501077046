<h1 mat-dialog-title>{{'privacy' | translate}}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div innerHTML="{{ 'privacy_content' | translate}}"></div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions >
    <mat-checkbox class="example-margin" [(ngModel)]="accepted">
      Accetto tutti i termini e le condizioni
    </mat-checkbox>
    <button mat-raised-button (click)="acceptPolicy()" [disabled]="!accepted">Chiudi</button>
</div>
