import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { MacMacchine } from '../objects/macMacchine';
import { HeatTreatments } from '../objects/heatTreatments';
import { AlrElencoAllarmi } from '../objects/alrAlarmsList';
import { UteUser } from '../objects/uteUser';
import { FlvFlavour } from '../objects/flvFlavour';
import { TranslateService } from '@ngx-translate/core';
import { UserLogged } from '../objects/user-logged';
import { AppConfigService } from '../services/app-config.service';
import { ModelService } from '../services/model.service';
import { ModElencoModelli } from '../objects/modElencoModelli';

@Component({
  selector: 'app-heatrreatments',
  templateUrl: './heatrreatments.component.html',
  styleUrls: ['./heatrreatments.component.scss'],
})
export class HeatrreatmentsComponent implements OnInit {

  machineSessioned: MacMacchine = new MacMacchine();
  displayedColumns: string[] = ['date', 'type', 'drisc', 'dover', 'draff', 'tmax', 'tmin', 'ricetta', 'peso', 'evento', 'esito'];
  dataSourceHeats;
  alarmsListStrings: AlrElencoAllarmi[] = [];
  //modelList: ModElencoModelli[] = [];
  //modelsListString: ModElencoModelli[] = [];
  userLogged: UserLogged;
  flavourArray: FlvFlavour[] = [];

  constructor(
    private loadingService: LoaderService,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private route: Router,
    private translateService: TranslateService,
    private appConfigService:AppConfigService,
    private modelService:ModelService
  ) { }

  ngOnInit() {
   this.route.dispose();
    this.machineSessioned = this.sessionService.currentMachineValue;

    this.getHeatTreatments();
    this.getFlavours();
  }

  getHeatTreatments() {
    this.httpClient.get<HeatTreatments[]>(this.appConfigService.apiBaseUrl + "/HeatTreatments/" + this.machineSessioned.maC_Numero_Serie_MS)
    .subscribe(res => {
      console.log(res);
      this.dataSourceHeats = res;
    });
  }



  getTypeString(typeNum: number){
    if(typeNum == 2){
      return this.translateService.instant("alta_pastorizzazione");
    } else if (typeNum == 4){
      return this.translateService.instant("bassa_pastorizzazione");
    }
  }

  getFlavours() {
    this.userLogged = this.sessionService.currentUserValue;

    this.httpClient.get<FlvFlavour[]>(this.appConfigService.apiBaseUrl + "/flavours/" + this.userLogged.utE_Id) // errore bug dell'ide, la property c'è.
    .subscribe(res => {
      console.log(res);
      this.flavourArray = res;
    });
  }

  setFlavourString(flavourId: number): string {
    return "-";
   /* try {
      const tmpFlavour: FlvFlavour = this.flavourArray.filter(x => x.flV_Flavour_ID == flavourId)[0];
      return tmpFlavour.flV_NameFlavour;
    } catch (error) {      
      try {
        return flavourId.toString();
      } catch (error) {      
        return "-";
      }
    }*/
  }
}
