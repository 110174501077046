import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MacMacchine } from '../objects/macMacchine';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss'],
})
export class CountersComponent implements OnInit, OnDestroy {

  machineSessioned: MacMacchine = new MacMacchine();

  loading = true;

  constructor(
    private route: Router,
    private sessionService: SessionService,
  ) {
  }

  ngOnInit() {
    this.machineSessioned = this.sessionService.currentMachineValue;   
  }

  ngOnDestroy() {
    this.route.dispose();
  }

}
