import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { UserLogged } from '../objects/user-logged';
import moment from 'moment';
import { ConfigUtil } from '../utils/configUtils';
import { SessionService } from './session.service';
import { CredentialSession } from '../objects/userSession';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    
    constructor(private http: HttpClient, private appConfigService: AppConfigService,private sessionService: SessionService) {
        
    }

    
    public login(username: string, password: string) {
        return this.http.post<any>(this.appConfigService.apiBaseUrl + `/login`, { username, password },  {observe: 'response'})
            .pipe(map((resp) => {
                console.log('ok');
                // login successful if there's a jwt token in the response
                if (resp && resp.headers) {
                    const token = resp.headers.get('X-Token');
                    if(token){
                    console.log(token);
                    let us = new UserLogged(token);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.sessionService.setLoginSession(us);
                       
                    }
                }
                else{
                    console.log('Token error');
                }
                return true;
            },
            error => {
                console.log('error');
                return false;
            })          
        );
    }

    logout() {
        const credential: CredentialSession = this.sessionService.currentCredentialValue;

        if (credential!=null && !credential.remember_credentials) {
            this.sessionService.setCredentialSession(null);
        } 
        this.sessionService.setMachineSession(null);
        this.sessionService.setLoginSession(null);
    }
 
    public isLoggedIn() {
        if(this.sessionService.currentUserValue){
            let expiration = this.sessionService.currentUserValue.exp;
            let a = new Date();
            return (a < expiration);
        }
        return false;
    }
}