import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { MacMacchine } from '../objects/macMacchine';

@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
  styleUrls: ['./realtime.component.scss'],
})
export class RealtimeComponent implements OnInit {

  machineSessioned: MacMacchine = new MacMacchine();

  constructor(
    private sessionService: SessionService
    ) {
      this.machineSessioned = sessionService.currentMachineValue;
      console.log('RealTime default component: ', this.machineSessioned);
    }

  ngOnInit(): void {
  }
}
