import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { LanguageService } from '../services/language.service';
import { ForgotpasswordModalComponent } from '../modals/forgotpassword-modal/forgotpassword-modal.component';
import { SnackbarService } from '../services/snackbar.service';
import { CredentialSession } from '../objects/userSession';
import { TranslateService } from '@ngx-translate/core';
import { UteUser } from '../objects/uteUser';
import { version } from '../../../package.json';
import { AuthenticationService } from '../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  languages;
  showLoading: boolean = false;
  emailInput: string = "";
  passwordInput: string = "";   
  userCredential: CredentialSession = new CredentialSession();
  public version: string = version;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private authenticateService: AuthenticationService,
    private sessionService: SessionService
  ) {
      //set default language and set menu languages
      this.languageService.setDefaultLanguageAtStart();
      this.languages = this.languageService.getLanguages();
   }

  ngOnInit() {
    if(this.sessionService.currentCredentialValue!=null){
      this.userCredential = this.sessionService.currentCredentialValue;
    }
  }

  doLogin() {
    if(this.userCredential.username !== "" && this.userCredential.password !== "") {
      
      let userTmp: UteUser = new UteUser();
      userTmp.utE_Nome = this.userCredential.username
      userTmp.utE_Password = this.userCredential.password;
      
      this.authenticateService.login(this.userCredential.username, this.userCredential.password) .subscribe(
        data => {
            this.userCredential.language = this.translate.currentLang;
            this.showLoading = false;
            this.sessionService.setCredentialSession(this.userCredential);
            this.router.navigate(['/dashboard']);
        },
        error => {
            console.log(error);
            this.showLoading = false;
            this.snackbarService.showSnackBar(this.translate.instant('password_error'));
        });
    } else {
      this.snackbarService.showSnackBar(this.translate.instant('complete_all_inputs'))
      this.showLoading = false;
    }
  }

  setLanguage(lng: string){
    this.languageService.setLanguage(lng);
  }

  forgetPassword() {
    this.dialog.open(ForgotpasswordModalComponent, {
      width:'auto'
    });
  }
}
