import { Pipe, PipeTransform } from '@angular/core';
import { MacMacchine } from 'src/app/objects/macMacchine';
import { ParElencoParametri } from 'src/app/objects/parElencoParametri';
import { SmoStoricoModifiche } from 'src/app/objects/smoStoricoModifiche';
import { SessionService } from 'src/app/services/session.service';
import { ConfigUtil } from '../configUtils';

@Pipe({
  name: 'parameterName'
})
export class ParameterNamePipe implements PipeTransform {
  machineSessioned: MacMacchine = new MacMacchine();
  constructor(public sessionService: SessionService) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }
  
  transform(value: SmoStoricoModifiche, parametersList:ParElencoParametri[]): any {
    try {
      let idToPass = this.machineSessioned.maC_ModelloSx;
      if(this.machineSessioned.maC_ModelloSx !== 2)
      {
        // debugger;
        idToPass = 0;
        if(value.smO_SxDx === 1){ idToPass = this.machineSessioned.maC_ModelloSx; }
        if(value.smO_SxDx === 2){ idToPass = this.machineSessioned.maC_ModelloDx; }
        if(value.smO_SxDx === 3){ idToPass = this.machineSessioned.maC_ModelloCen; }
      }
      if(value.smO_Codice>=0){
        const al = parametersList.find(x => x.paR_IdParametro === value.smO_Codice && x.paR_IdModello === idToPass);
        return al.paR_Descrizione;
      }
      else{
        return value.smO_Descrizione;
      }
    } catch (error) {
      return "-";
    }
  }

}
