export enum FrigomatTopicsPub {
    realtime = 'frigomat/server/[SN]/realtime/request',
    currenStatus = 'frigomat/server/[SN]/status/request',
    addRecipe = 'frigomat/server/[SN]/recipes/add',
    editRecipe = 'frigomat/server/[SN]/recipes/edit',
    deleteRecipe = 'frigomat/server/[SN]/recipes/delete',
    recipeList = 'frigomat/server/[SN]/recipes/request',
    stopCmdSection = 'frigomat/server/[SN]/[section]/stop',
    stopCmd = 'frigomat/server/[SN]/stop',
    conservationCmdSection = 'frigomat/server/[SN]/[section]/nightConservation',
    conservationCmd = 'frigomat/server/[SN]/[section]/nightConservation',
    counters ='frigomat/server/[SN]/counters',
    parameters =  'frigomat/server/[SN]/parameters/request',
    setParameters =  'frigomat/server/[SN]/parameters',
  }

export enum FrigomatTopicsSub {
    realtimeSection = 'frigomat/[SN]/server/+/realtime',
    currenStatusSection = 'frigomat/[SN]/server/+/status',
    realtime = 'frigomat/[SN]/server/realtime',
    currenStatus = 'frigomat/[SN]/server/status',
    recipeList = 'frigomat/[SN]/server/recipes/publish',
    counters =  'frigomat/[SN]/server/counters',
    parameters =  'frigomat/[SN]/server/parameters',
    history ='frigomat/[SN]/server/parameters/history/modification'
}
