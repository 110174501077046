<h4 mat-dialog-title>{{'edit_user' | translate}}</h4>
<div mat-dialog-content>
  <form [formGroup]="formEdit" id="ngFormEdit" (ngSubmit)="save()">
    <mat-form-field>
      <input matInput placeholder="{{'username' | translate }}" formControlName="ute_nameCtrl" required>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'description' | translate }}" formControlName="ute_descriptionCtrl">
    </mat-form-field>  
    <mat-form-field>
      <mat-select placeholder="{{'role' | translate }}" formControlName="ute_levelCtrl" required>
        <mat-option [value]="4"> {{ 'customer' | translate }} </mat-option>
        <mat-option [value]="3"> {{ 'technician' | translate }} </mat-option>
        <mat-option [value]="2"> {{ 'dealer' | translate }} </mat-option>
        <mat-option [value]="1">{{ 'administrator' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="{{'enabled' | translate }}" formControlName="ute_enabledCtrl">
        <mat-option [value]="0">{{ 'disabled' | translate }}</mat-option>
        <mat-option [value]="1"> {{ 'enabled' | translate }} </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button style="width: 100%" [disabled]="!formEdit.valid" form="ngFormEdit" mat-raised-button color="primary" type="submit">{{ 'confirm' | translate }}</button>
  <button style="width: 100%" color="primary" mat-button mat-dialog-close>{{ 'close' | translate }}</button>
</div>  
