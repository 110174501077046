import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MacMacchine } from '../../objects/macMacchine';
import { SessionService } from '../../services/session.service';
import { RgsRegistrazione } from '../../objects/rgsRegistrazione';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'app-counters-soft',
  templateUrl: './counters-soft.component.html',
  styleUrls: ['./counters-soft.component.scss'],
})
export class CountersSoftComponent implements OnInit, OnDestroy, AfterViewInit {

  machineSessioned: MacMacchine = new MacMacchine();
  countersObj: RgsRegistrazione = new RgsRegistrazione();
  @ViewChild(MatTabGroup, {static:true}) tabGroup: MatTabGroup;

  leftLabel = '-';
  rightLabel: string;
  centerLabel: string;
  mix1Label: string;
  mix2Label: string;
  loading = true;

  constructor(
    private route: Router,
    private sessionService: SessionService,
    private transalationService: TranslateService,
    private registrationService:RegistrationService
  ) {
  }

  ngOnInit() {

    
    // console.log('Counters INIT');
    this.machineSessioned = this.sessionService.currentMachineValue;
    // console.log('macchina sessioned: ', this.machineSessioned);
    this.getCounters();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.leftLabel = this.transalationService.instant('left');
      this.rightLabel = this.transalationService.instant('right');
      this.centerLabel = this.transalationService.instant('center');
      this.mix1Label = this.transalationService.instant('mix1');
      this.mix2Label = this.transalationService.instant('mix2');
     }, 250);
  }

  getCounters() {
    this
    this.registrationService.getFirst(this.machineSessioned.maC_Numero_Serie_MS)
    .subscribe(res => {
      this.countersObj = res;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.route.dispose();
  }

}
