<form [formGroup]="form" id="ngForm" (ngSubmit)="update()">
  <mat-form-field  > 
    <input matInput required type="text" formControlName="recipe_nameCtrl" placeholder="{{'name' | translate}}" >
    <mat-error *ngIf= "form.controls.recipe_nameCtrl.errors?.nameJustInUse">
      {{'name_just_in_use' | translate}}
    </mat-error>
    <mat-error *ngIf= "form.controls.recipe_nameCtrl.errors?.required">
      {{'please_specify_name' | translate}}
    </mat-error>
  </mat-form-field>

  <mat-form-field> 
    <input type="number"  [min]="TankTempMin" [max]="TankTempMax" step="1" required matInput formControlName="recipe_tankTempCtrl" placeholder="{{'temperature_c' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_tankTempCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_tankTempCtrl.errors?.max">
      {{'range_error_value' | translate : { min: TankTempMin, max: TankTempMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_tankTempCtrl.errors?.min">
      {{'range_error_value' | translate :{ min: TankTempMin, max: TankTempMax } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field> 
    <input type="number"  [min]="ConsistencyMin" [max]="ConsistencyMax" step="1" required matInput formControlName="recipe_consistencyCtrl" placeholder="{{'consistency' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_consistencyCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_consistencyCtrl.errors?.max">
      {{'range_error_value' | translate : { min: ConsistencyMin, max: ConsistencyMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_consistencyCtrl.errors?.min">
      {{'range_error_value' | translate :{ min: ConsistencyMin, max: ConsistencyMax } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field> 
    <input type="number"  [min]="OnOffMin" [max]="OnOffMax" step="1" required matInput formControlName="recipe_conservationOffCtrl" placeholder="{{'conservation_off_m' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_conservationOffCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_conservationOffCtrl.errors?.max">
      {{'range_error_value' | translate : { min: OnOffMin, max: OnOffMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_conservationOffCtrl.errors?.min">
      {{'range_error_value' | translate :{ min: OnOffMin, max: OnOffMax } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field> 
    <input type="number"  [min]="OnOffMin" [max]="OnOffMax" step="1" required matInput formControlName="recipe_conservationOnCtrl" placeholder="{{'conservation_on_s' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_conservationOnCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_conservationOnCtrl.errors?.max">
      {{'range_error_value' | translate : { min: OnOffMin, max: OnOffMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_conservationOnCtrl.errors?.min">
      {{'range_error_value' | translate :{ min: OnOffMin, max: OnOffMax } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field> 
    <input type="number"  [min]="OnOffMin" [max]="OnOffMax" step="1" required matInput formControlName="recipe_productionOffCtrl" placeholder="{{'production_off_m' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_productionOffCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_productionOffCtrl.errors?.max">
      {{'range_error_value' | translate : { min: OnOffMin, max: OnOffMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_productionOffCtrl.errors?.min">
      {{'range_error_value' | translate :{ min: OnOffMin, max: OnOffMax } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field> 
    <input type="number"  [min]="OnOffMin" [max]="OnOffMax" step="1" required matInput formControlName="recipe_productionOnCtrl" placeholder="{{'production_on_s' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_productionOnCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_productionOnCtrl.errors?.max">
      {{'range_error_value' | translate : { min: OnOffMin, max: OnOffMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_productionOnCtrl.errors?.min">
      {{'range_error_value' | translate :{ min: OnOffMin, max: OnOffMax } }}
    </mat-error>
  </mat-form-field>
  <br>
  <mat-slide-toggle formControlName="recipe_creativeCtrl">{{'creative' | translate}}</mat-slide-toggle>
</form>
