import { Pipe, PipeTransform } from '@angular/core';
import { EmaMachineList } from 'src/app/objects/emaMachineList';

@Pipe({
  name: 'model'
})
export class ModelPipe implements PipeTransform {

  transform(emaList: EmaMachineList[], id_model: number): any {
    return emaList.find(ema => {return ema.emA_Id === id_model});
    
  }
}

