<mat-card class="mat-elevation-z8 realtime-container">
  <mat-card-title-group>
    <mat-card-subtitle>{{'booked_sector' | translate}}:</mat-card-subtitle>
    <mat-card-title *ngIf="(realTimeEnableSector === 0 || realTimeEnableSector === 49)">
      {{'no_sector_booked'| translate}}</mat-card-title>
    <mat-card-title *ngIf="(realTimeEnableSector !== 0 && realTimeEnableSector !== 49)">
      {{realTimeEnableSector}} </mat-card-title>
    <button mat-raised-button color="warn" (click)="stopRealTime()">Stop Real-Time</button>
  </mat-card-title-group>
  <br>
  <mat-card-content>
    <div class="card-content" *ngFor="let sector of listSectors">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" (click)="startRealTime(sector)"
        style="width: 100%; margin-top: 2%; margin-bottom: 2%;">
        {{'book_realtime'| translate}}</button>
      <div class="mat-card-container">
        <mat-label>
          {{'status'| translate}}: <mat-icon *ngIf="(realtimeObj.sx_dx === sector)"
            style="color: green;">wifi</mat-icon><mat-icon *ngIf="(realtimeObj.sx_dx !== sector)"
            style="color: red;">wifi</mat-icon>
        </mat-label>
        <div class="states">

          {{'status'| translate}}
          <span *ngIf="(realtimeObj.sx_dx === sector)" [ngSwitch]="realtimeObj.stato">
            <div *ngSwitchCase="[1, 2, 4, 8].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <mat-icon>play_arrow</mat-icon>
            </div>
            <div *ngSwitchCase="[32, 64].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <mat-icon class="rotate-8-s">refresh</mat-icon>
            </div>
            <div *ngSwitchCase="[16,128].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
            <div *ngSwitchCase="[0].includes(realtimeObj.stato) ? realtimeObj.stato : -1">
              <mat-icon>stop</mat-icon>
            </div>
            <div *ngSwitchDefault>
              <mat-icon>stop</mat-icon>
            </div>
          </span>
        </div>
        <div class="states">
          <div><mat-icon>notifications</mat-icon> {{'alarms_on_now' | translate}}</div>
          <span> {{ (realtimeObj.allarmi>0 ? 'yes':'-') | translate | uppercase }}
          </span>
        </div>
        <div class="states">
          <div> {{'consistency' | translate}}</div>
          {{realtimeObj.consistenza}}
        </div>
      </div>
      <div class="mat-card-container">
        {{'send_command' | translate | uppercase}}
        <button mat-raised-button color="warn" (click)="sendCommandRealTime(sector, 100)">{{'stop_sector' | translate |
          uppercase}}</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
