import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ThemeService } from 'ng2-charts';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { WindowHandleService } from 'src/app/services/window-handle.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private sessionService: SessionService,
    private route: Router,
    private windowHandleService: WindowHandleService
  ){}

  canActivate(): boolean {
    if(!this.sessionService.currentUserValue){
      this.route.navigate(['/login']);
      this.windowHandleService.showTopBarBehavior.next(false);
      return false;
    }
    return (this.sessionService.currentUserValue.utE_Livello==1);
  }
}
