import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FlavoursAnalysis } from '../objects/flavoursAnalysis';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService{
  endpoint: string;
  apiName:string='analysisFlavours';

  constructor( protected http: HttpClient, protected appConfigService:AppConfigService)
  {
    this.http=http;
    this.endpoint = appConfigService.apiBaseUrl;
  }

  read(serialNumberMs:number,startDate:Date, endDate:Date): Observable<FlavoursAnalysis[]> {
    console.log(this.apiName);
    return this.http.get<FlavoursAnalysis[]>(`${this.endpoint}/${this.apiName}?serialMS=${serialNumberMs}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
  }

  getList(serialNumberMs:number,startDate:Date, endDate:Date): Observable<any[]> {
    console.log(this.apiName);
    return this.http.get<any[]>(`${this.endpoint}/${this.apiName}/list?serialMS=${serialNumberMs}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
  }
}
