<div class="main-container">
    <div class="inside-main-container">
      <mat-card class="mat-elevation-z8">

        <mat-progress-bar *ngIf="!dataSourceModify" mode="indeterminate"></mat-progress-bar>
        <!-- <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate}}...">
        </mat-form-field> -->
        <table mat-table [dataSource]="dataSourceModify" matSort>

          <!-- Position Column -->
          <ng-container matColumnDef="parameter">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'parameter' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element | parameterName:parametersList }} </td>
          </ng-container>

          <ng-container matColumnDef="old">
            <th mat-header-cell *matHeaderCellDef> {{ 'old_parameter' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <!-- <div *ngIf="element.smO_Codice === 12 && machineSessioned.macModelloSx !== 8 && machineSessioned.macModelloSx !== 6">{{element.smO_SpVecchio/10}} </div>
              <div *ngIf="element.smO_Codice !== 12 && machineSessioned.macModelloSx !== 8">{{element.smO_SpVecchio}} </div>
              <div *ngIf="machineSessioned.macModelloSx === 8">{{element.smO_SpVecchio}} </div> -->
              <div *ngIf="machineSessioned.macModelloSx === 2">{{element.smO_SpVecchio/10}} </div>
              <div *ngIf="machineSessioned.macModelloSx !== 2">{{element.smO_SpVecchio}} </div>
            </td>
          </ng-container>


          <ng-container matColumnDef="new_c">
            <th mat-header-cell *matHeaderCellDef> {{ 'new_parameter' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <!-- <div *ngIf="element.smO_Codice === 12 && machineSessioned.macModelloSx !== 8">{{element.smO_SpNuovo/10}} </div>
              <div *ngIf="element.smO_Codice !== 12 && machineSessioned.macModelloSx !== 8">{{element.smO_SpNuovo}} </div>
              <div *ngIf="machineSessioned.macModelloSx === 8">{{element.smO_SpNuovo}} </div> -->
              <div *ngIf="machineSessioned.macModelloSx === 2">{{element.smO_SpNuovo/10}} </div>
              <div *ngIf="machineSessioned.macModelloSx !== 2">{{element.smO_SpNuovo}} </div>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="smO_SxDx">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <label *ngIf="machineSessioned.macModelloSx === 2">{{ 'sector' | translate}}</label>
              <label *ngIf="machineSessioned.macModelloSx !== 2">{{ 'sector_settore' | translate}}</label>
            </th>
            <td mat-cell *matCellDef="let element">
              <label *ngIf="element.smO_SxDx !== 0">{{element.smO_SxDx}}</label>
              <label *ngIf="element.smO_SxDx === 0">-</label>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="smO_DateTime" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'datetime' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.smO_DateTime | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 500, 1000]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
