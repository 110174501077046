import { Component, OnInit } from '@angular/core';
import { ConfigUtil } from '../utils/configUtils';
import { SessionService } from '../services/session.service';
import { MacMacchine } from '../objects/macMacchine';

@Component({
  selector: 'app-flavours',
  templateUrl: './flavours.component.html',
  styleUrls: ['./flavours.component.scss'],
})
export class FlavoursComponent implements OnInit {

  machineSessioned: MacMacchine;
  modelListCod:number[]=[];
  title:string[]=[];

  constructor(
    private sessionService: SessionService,
  ) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  ngOnInit() {
    if(this.machineSessioned.maC_ModelloSx!=null && this.machineSessioned.maC_ModelloSx!=0) 
    {
       this.modelListCod.push(this.machineSessioned.maC_ModelloSx);
    }
    if(this.machineSessioned.maC_ModelloCen!=null && this.machineSessioned.maC_ModelloCen!=this.machineSessioned.maC_ModelloSx && this.machineSessioned.maC_ModelloCen!=0)
    {
       this.modelListCod.push(this.machineSessioned.maC_ModelloCen);
    }
    if(this.machineSessioned.maC_ModelloDx!=null && this.modelListCod.findIndex(x=>x ==this.machineSessioned.maC_ModelloDx)<0 && this.machineSessioned.maC_ModelloDx!=0)
    {
       this.modelListCod.push(this.machineSessioned.maC_ModelloDx);
    }
  }

  setTitle(title:string,index:number){
    this.title.splice(index,0,title);
  }

}
