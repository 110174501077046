import { Pipe, PipeTransform } from '@angular/core';
import { CmdElencoComandi } from 'src/app/objects/cmdElencoComandi';
import { MacMacchine } from 'src/app/objects/macMacchine';
import { ModElencoModelli } from 'src/app/objects/modElencoModelli';
import { ScoStoricoComandi } from 'src/app/objects/scoStoricoComandi';
import { SessionService } from 'src/app/services/session.service';
import { ConfigUtil } from '../configUtils';

@Pipe({
  name: 'commandName'
})
export class CommandNamePipe implements PipeTransform {
  machineSessioned: MacMacchine = new MacMacchine();
  constructor(public sessionService: SessionService) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  transform(value: ScoStoricoComandi,commandsList:CmdElencoComandi[], modelsList:ModElencoModelli[]): any {
    try {
      let idToPass = this.machineSessioned.maC_ModelloSx;
      // solo la GX è diversa DA tutte le altre, la filtro nell'if
      if(this.machineSessioned.maC_ModelloSx !== 2){
        // tytte le altre macchine
        idToPass = 0;
        if(value.scO_SxDx === 1){ idToPass = this.machineSessioned.maC_ModelloSx; }
        if(value.scO_SxDx === 2){ idToPass = this.machineSessioned.maC_ModelloDx; }
        if(value.scO_SxDx === 3){ idToPass = this.machineSessioned.maC_ModelloCen; }
      }
      let idModel = modelsList.find(x => x.moD_Codice === idToPass);
      if(value.scO_Codice>=0){
        const al = commandsList.find(x => x.cmD_IdComando === value.scO_Codice && x.cmD_IdModello === idModel.moD_Codice);
        const res = al?al.cmD_Descrizione:'-';
        return res;
      }
      else{
        const res = value.scO_Descrizione===null ?'-':value.scO_Descrizione;
        return res;
      }
    } catch (error) {
      return "-";
    }
  }
}
