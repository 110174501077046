import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { Observable } from 'rxjs';
import { SetSetpointParameters } from '../objects/setSetpointParameters';

@Injectable({
  providedIn: 'root'
})
export class SetpointParameterService extends GenericCrudService<SetSetpointParameters,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"SetSetpointParametri");
  }

  readCustom(serialNumberMs,sector): Observable<SetSetpointParameters[]> {
    //this.loading = true;

    console.log(this.apiName);
    return this.http.get<SetSetpointParameters[]>(`${this.endpoint}/${this.apiName}?sector=${sector}&serialMS=${serialNumberMs}`);
  }

  write( set:SetSetpointParameters): Observable<any> {
    //this.loading = true;

    console.log(this.apiName);
    return this.http.post(`${this.endpoint}/${this.apiName}`,set);
  }




}
