import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MachineConnectedComponent } from 'src/app/machine-connected/machine-connected';
import { Recipe, RecipeTwist } from 'src/app/objects/recipe';
import { AwsIotProviderService } from 'src/app/services/aws-iot-provider.service';
import { ModelService } from 'src/app/services/model.service';
import { SessionService } from 'src/app/services/session.service';
import { FrigomatTopicsPub } from 'src/app/utils/enums/frigomatTopics';
import { RecipeType } from 'src/app/utils/enums/recipeType';



export abstract class RecipeFormComponent<Gen extends Recipe> extends MachineConnectedComponent {
  
  protected connect() {
  }

  form: FormGroup;
  @Input() entity:Gen;
  entityTemp:Gen;
  @Input() entityList:Gen[];
  isNew:boolean;
  @Output() protected onFormChange = new EventEmitter<any>();
 
  constructor(
    protected fb: FormBuilder,
    protected awsIotProviderService:AwsIotProviderService,
    protected sessionService:SessionService,
    protected modelService:ModelService
  ) { 
    super(awsIotProviderService, sessionService, modelService);
  }

  newRecipeValidator(c:AbstractControl, listRecipe:Recipe[], recipe:Recipe):{ [s: string]: boolean }
  {
    var currentIdx =-2;
    if(listRecipe.length>0){
      var index = listRecipe.map(function(d) { return d.name; }).indexOf(c.value)
      if (recipe)
      {
        currentIdx = listRecipe.map(function(d) { return d.name }).indexOf(recipe.name);
      }
      if(index>=0 && index!= currentIdx)
      {
        return { nameJustInUse: index>=0};
      }
    }
  }

  public get ConsistencyMin(){
    return Recipe.ConsistencyMin;
  }

  public get ConsistencyMax(){
    return Recipe.ConsistencyMax;
  }

  abstract update(isNew:boolean):boolean; 
  
  abstract delete():boolean; 
}
