
    <div class="offlineCard" *ngIf="showAlert">
      <mat-card class="mat-elevation-z8">
        <h2>{{ 'machine_offline' | translate }}</h2>
        <mat-icon>perm_scan_wifi</mat-icon>
      </mat-card>
    </div>
    <mat-card *ngIf="showLoading" class="mat-elevation-z8">
      <h3>{{ 'waiting' | translate }}...</h3>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card>
    <mat-card *ngIf="!showLoading && !showAlert" class="mat-elevation-z8">
      <h3>{{ 'strokecounter' | translate }}</h3>
      <div class="counters">
        <div><img src="../../assets/img/Contaconi.png" height="100px"> </div>
      </div>
      <h4>{{ 'total' | translate }}</h4>
      <div class="counters" *ngIf="counter!=null">
        <div  class="counterContent" *ngIf="counter.LeftTotal!=null">
          <span><b>{{"left" | translate }} </b></span>
          <span><b>{{counter.LeftTotal}}</b></span>
          <span><b>{{counter.LeftTotal | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.MixLCTotal!=null">
          <span><b>{{("mix_left_center") | translate}}</b></span>
          <span><b>{{counter.MixLCTotal}}</b></span>
          <span><b>{{counter.MixLCTotal | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.MixTotal!=null">
          <span><b>Mix</b></span>
          <span><b>{{counter.MixTotal}}</b></span>
          <span><b>{{counter.MixTotal | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.CenterTotal!=null">
          <span><b>{{"center" | translate}}</b></span>
          <span><b>{{counter.CenterTotal}}</b></span>
          <span><b>{{counter.CenterTotal | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.MixCRTotal!=null">
          <span><b>{{"mix_center_right" | translate}}</b></span>
          <span><b>{{counter.MixCRTotal}}</b></span>
          <span><b>{{counter.MixCRTotal | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.RightTotal!=null">
          <span><b>{{"right" | translate}}</b></span>
          <span><b>{{ counter.RightTotal}}</b></span>
          <span><b>{{ counter.RightTotal | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
      </div>
      <h4>{{ 'daily' | translate }}</h4>
      <div class="counters" *ngIf="counter!=null">
        <div  class="counterContent" *ngIf="counter.Left!=null">
          <span><b>{{"left" | translate }} </b></span>
          <span><b>{{counter.Left}}</b></span>
          <span><b>{{counter.Left | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.MixLC!=null">
          <span><b>{{("mix_left_center") | translate}}</b></span>
          <span><b>{{counter.MixLC}}</b></span>
          <span><b>{{counter.MixLC | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.Mix!=null">
          <span><b>Mix</b></span>
          <span><b>{{counter.Mix}}</b></span>
          <span><b>{{counter.Mix | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.Center!=null">
          <span><b>{{"center" | translate}}</b></span>
          <span><b>{{counter.Center}}</b></span>
          <span><b>{{counter.Center | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.MixCR!=null">
          <span><b>{{"mix_center_right" | translate}}</b></span>
          <span><b>{{counter.MixCR}}</b></span>
          <span><b>{{counter.MixCR | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" *ngIf="counter.Right!=null">
          <span><b>{{"right" | translate}}</b></span>
          <span><b>{{ counter.Right}}</b></span>
          <span><b>{{ counter.Right| kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
      </div>
      <h4>{{ 'all' | translate }}</h4>
      <div class="counters" *ngIf="counter!=null">
        <div  class="counterContent">
          <span><b>{{"total" | translate }} </b></span>
          <span><b>{{counter.Total}}</b></span>
          <span><b>{{counter.Total | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
        <div  class="counterContent" >
          <span><b>{{"daily" | translate}}</b></span>
          <span><b>{{counter.Daily}}</b></span>
          <span><b>{{counter.Daily | kgCounter:machineSession.maC_Grammi }}</b></span>
        </div>
      </div>
    </mat-card>
