<div class="main-container">
    <div class="inside-main-container">
      <mat-card  class="mat-elevation-z8">
        <mat-progress-bar *ngIf="!dataSourceAlarms" mode="indeterminate"></mat-progress-bar>
        <!-- <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate}}...">
        </mat-form-field> -->
        <table mat-table [dataSource]="dataSourceAlarms" matSort>

          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{ 'alarm' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{ element | alarmName:alarmsList:modelsList }}  </td> <!-- {{setAlarmString(element)}} -->
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="saL_SxDx">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header>
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">{{ 'sector' | translate}}</label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">{{ 'sector_settore' | translate}}</label>
            </th>
            <td mat-cell *matCellDef="let element">
              <label *ngIf="element.salSxDx !== 0">{{element.saL_SxDx}}</label>
              <label *ngIf="element.salSxDx === 0">-</label>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="saL_DateTime">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{ 'datetime' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.saL_DateTime | date:'dd/MM/yy HH:mm'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 500, 1000]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
