<div class="main-container">
    <div class="inside-main-container">
      <mat-card class="mat-elevation-z8">
      <mat-progress-bar *ngIf="!dataSourceCommands" mode="indeterminate"></mat-progress-bar>

        <!-- <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'filter_label' | translate}}...">
        </mat-form-field> -->
        <table mat-table [dataSource]="dataSourceCommands" matSort>

          <!-- Position Column -->
          <ng-container matColumnDef="command">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'command' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{ element | commandName:commandsList:modelsList}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="scO_SxDx">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">{{ 'sector' | translate}}</label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">{{ 'sector_settore' | translate}}</label>
            </th>
            <td mat-cell *matCellDef="let element" >
              <label *ngIf="element.scO_SxDx !== 0">{{element?.scO_SxDx}}</label>
              <label *ngIf="element.scO_SxDx === 0">-</label>
            </td>
          </ng-container>
          <ng-container matColumnDef="scO_Peso" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'weight' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <div>{{element | weight:modelsList}} </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="ricetta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'flavour' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{ element | flavourName:flavourArray:flavourTwinArray:modelListCod}} </td>
          </ng-container>
          <ng-container matColumnDef="scO_DateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'datetime' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.scO_DateTime | date:'dd/MM/yyyy HH:mm'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 50, 500, 1000]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
