import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { MacMacchine } from '../objects/macMacchine';
import { ConfigUtil } from '../utils/configUtils';
import { ParElencoParametri } from '../objects/parElencoParametri';
import { map} from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ModificationHistoryService } from '../services/modification-history.service';
import { ParameterService } from '../services/parameter.service';
import { combineLatest } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { ParameterNamePipe } from '../utils/pipes/parameter-name.pipe';
import { ModelService } from '../services/model.service';
import { ModElencoModelli } from '../objects/modElencoModelli';

@Component({
  selector: 'app-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.scss'],
})
export class ModifyComponent implements OnInit {

  machineSessioned: MacMacchine = new MacMacchine();
  displayedColumns: string[] = ['parameter', 'old', 'new_c', 'smO_SxDx', 'smO_DateTime'];
  dataSourceModify;
  parametersList: ParElencoParametri[] = [];
  modelsList: ModElencoModelli[] = [];
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private sessionService: SessionService,
    private route: Router,
    private modificationHistoryService:ModificationHistoryService,
    private parameterService:ParameterService,
    private modelService:ModelService
  ) {
      this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
   }

  ngOnInit() {

    const parameters$ = this.parameterService.listObservable;  
    const history$ = this.modificationHistoryService.read(this.machineSessioned.maC_Numero_Serie_MS);
    const model$ = this.modelService.listObservable;

    combineLatest( parameters$,history$,model$).pipe(
      map(([parameters$,history$,models$]) => ({
        parameterList:parameters$,
        historyList:history$,
        modelList:models$
      })))
    .subscribe(pair => {
      this.parametersList = pair.parameterList;
      this.modelsList = pair.modelList;
      this.dataSourceModify = new MatTableDataSource(pair.historyList);
      this.setCustomDataAccessor();
      this.dataSourceModify.paginator = this.paginator;
      this.dataSourceModify.sort = this.sort;
    });
  }

  setCustomDataAccessor() {
    const paramPipe = new ParameterNamePipe(this.sessionService);
  
    this.dataSourceModify.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'parameter':
          return paramPipe.transform(item,this.parametersList);
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.dataSourceModify.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.dataSourceModify = [];
    console.log("close modify")
    this.route.dispose();
  }

}
