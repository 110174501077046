import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScoStoricoComandi } from '../objects/scoStoricoComandi';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommandHistoryService{
  endpoint: string;
  apiName:string='commandsHistory';

  constructor( protected http: HttpClient, protected appConfigService:AppConfigService)
  {
    this.http=http;
    this.endpoint = appConfigService.apiBaseUrl;
  }

  read(serialNumberMs:number): Observable<ScoStoricoComandi[]> {
    //this.loading = true;

    console.log(this.apiName);
    return this.http.get<ScoStoricoComandi[]>(`${this.endpoint}/${this.apiName}?serialMS=${serialNumberMs}`);
  }
}