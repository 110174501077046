import { Component, OnInit } from "@angular/core";
import { SessionService } from "../services/session.service";
import { MacMacchine } from "../objects/macMacchine";

@Component({
  selector: "app-parameter",
  templateUrl: "./parameter.component.html",
  styleUrls: ["./parameter.component.scss"],
})
export class ParameterComponent implements OnInit {
  machineSessioned: MacMacchine = new MacMacchine();

  constructor(
    private sessionService: SessionService
    ) {
      this.machineSessioned = sessionService.currentMachineValue;
      console.log('RealTime default component: ', this.machineSessioned);
    }

  ngOnInit(): void {
  }

}
