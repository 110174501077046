import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MacMacchine } from '../objects/macMacchine';
import { UteUser } from '../objects/uteUser';
import { EditassociationModalComponent } from '../modals/editassociation-modal/editassociation-modal.component';
import { EmaMachineList } from '../objects/emaMachineList';
import { SessionService } from '../services/session.service';
import { MqttService } from 'ngx-mqtt';
import { UserLogged } from '../objects/user-logged';
import { AppConfigService } from '../services/app-config.service';
import { UserService } from '../services/user.service';
import { AssociationService } from '../services/association.service';
import { AumAssociationUserMachine } from '../objects/aumAssociationUserMachine';
import { FormBuilder } from '@angular/forms';
import { UserRole } from '../utils/enums/userRole';
import { EmaService } from '../services/ema.service';
import { ModelPipe } from '../utils/pipes/model.pipe'
import { UserPipe } from '../utils/pipes/user.pipe';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss'],
})
export class AssociationsComponent implements OnInit {

  displayedColumns: string[] = ['maC_Matricola', 'maC_Numero_Serie_MS', 'model', 'maC_Indirizzo', 'dealer', 'customer', 'maC_Pagamento', 'grams','action'];
  dataSourceAssociation:MatTableDataSource<AumAssociationUserMachine> = new MatTableDataSource<AumAssociationUserMachine>();
  userList: UteUser[] = [];
  machinesModelsList: EmaMachineList[] = [];
  machineSession: MacMacchine;
  userSession: UserLogged;
  machinesList: MacMacchine[] = [];
  showSaveAssociation = false;
  loading = true;
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  // inputs assocation
  machinesModelsListType2Filtered;
  modelloType2Input;
  combinataShow = false;
  jsonToPrint;

  
  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private appConfigService: AppConfigService,
    private mqttService: MqttService,
    private userService: UserService,
    private associationService: AssociationService,
    private emaService: EmaService,
    protected fb: FormBuilder
  ) {
   }

  ngOnInit() {
    this.userSession = this.sessionService.currentUserValue;
    this.associationService.listObservable.subscribe(
      (data: AumAssociationUserMachine[]) => {
        this.dataSourceAssociation = new MatTableDataSource(data);
        this.dataSourceAssociation.paginator = this.paginator;
        this.dataSourceAssociation.sort = this.sort;
        this.setFilterPredicate();
        this.setCustomDataAccessor();
        this.loading = false;
        },
      error => console.log('error'),
      ()=>console.log('completeLoad')
    );
    this.userService.listObservable.subscribe(
      (data: UteUser[]) => {
        this.userList = data;
        },
      error => console.log('error'),
      ()=>console.log('completeLoad')
    );
    this.emaService.listObservable.subscribe(
      (data: EmaMachineList[]) => {
        this.machinesModelsList = data;
        },
      error => console.log('error'),
      ()=>console.log('completeLoad')
    );
    this.associationService.load();
  }

  public get userRole(): typeof UserRole {
    return UserRole; 
  }

  setFilterPredicate(){
    let modPipe = new ModelPipe();
    let userPipe = new UserPipe();

    this.dataSourceAssociation.filterPredicate =
      (data: AumAssociationUserMachine, filter: string) => !filter || data.maC_Matricola?.includes(filter)  ||  data.maC_Numero_Serie_MS?.toString().includes(filter) || data.maC_Indirizzo?.includes(filter) ||  modPipe.transform(this.machinesModelsList,data.maC_Macchina)?.emA_Descrizione.toLowerCase().includes(filter) ||  userPipe.transform(this.userList,data.dealerId)?.utE_Nome.toLowerCase().includes(filter) ||  modPipe.transform(this.machinesModelsList,data.maC_Macchina)?.emA_Descrizione.toLowerCase().includes(filter) ||  userPipe.transform(this.userList,data.customerId)?.utE_Nome.toLowerCase().includes(filter) ;
  }

  setCustomDataAccessor() {
    let modPipe = new ModelPipe();
    let userPipe = new UserPipe();
    this.dataSourceAssociation.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'model':
          return modPipe.transform(this.machinesModelsList,item.maC_Macchina)?.emA_Descrizione.toLowerCase();
        case 'dealer':
            return userPipe.transform(this.userList,item.dealerId)?.utE_Nome.toLowerCase()
        case 'customer':
              return userPipe.transform(this.userList,item.customerId)?.utE_Nome.toLowerCase()
        default:
          return item[property];
      }
    };
  }

  /*onSearchChange(searchValue: String) {
    const tmpMachine = this.machinesList.filter(x => x.maC_Matricola === searchValue)[0];

    if (tmpMachine !== undefined || searchValue === '') {
      this.showSaveAssociation = false;
    } else {
      this.showSaveAssociation = true;
    }
  }*/

  editAssociation(machine: AumAssociationUserMachine) {
    
    const dialog = this.dialog.open(EditassociationModalComponent, {
      width:'600px',
      data: machine
    });

    // Create subscription
    dialog.afterClosed().subscribe(x => {
        if(x){
          this.associationService.load();
        }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceAssociation.filter = filterValue.trim().toLowerCase();
  }

  lockUnlock(matricola: string, lockunlock: number) {
    // TODO solo per le soft nella versione di fine giugno
    if (confirm(this.translateService.instant('machine_online_sure'))) {
      // console.log(machine);
      const u8 = new Uint8Array(12);
      u8[0] = 1;
      u8[1] = 0;
      u8[2] = lockunlock;
      u8[3] = 0;
      u8[4] = 0;
      u8[5] = 0;

      const decoder = new TextDecoder('utf8');
      const b64encoded = btoa(decoder.decode(u8));

      console.log(b64encoded);

      // publish request real-time
      const topic = 'Frigomat/Portal/' + matricola;
      console.log(topic);
      this.mqttService.publish(topic, b64encoded)
      .subscribe(res => {
        console.log('sended Portal lock unlock');
        alert(this.translateService.instant('machine_return_sended'));
      });
    }
  }

  resetMatricola(serialNumberMs: number) {
    if (confirm('Sei sicuro? Azione irreversibile.')) {
      // Save it!
      console.log('Thing was saved to the database.');
      // this.jsonToPrint = machine;
      this.httpClient.post<number>(this.appConfigService.apiBaseUrl + '/resetmatricola', serialNumberMs)
      .subscribe(success => {
        this.snackBarService.showSnackBar('Operazione eseguita con successo.');
        this.associationService.load();
      }, err => {
        console.log('Error reset matricola: ', err);
      })
    }
  }
}
