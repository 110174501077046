import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { MachineConnectedComponent } from '../../machine-connected/machine-connected';
import { AwsIotProviderService } from '../../services/aws-iot-provider.service';
import { LoaderService } from '../../services/loader.service';
import { ModelService } from '../../services/model.service';
import { SessionService } from '../../services/session.service';
import { SnackbarService } from '../../services/snackbar.service';
import { FrigomatTopicsPub, FrigomatTopicsSub } from '../../utils/enums/frigomatTopics';


@Component({
  selector: 'app-realtime-twist',
  templateUrl: './realtime-twist.component.html',
  styleUrls: ['./realtime-twist.component.scss']
})
export class RealtimeTwistComponent extends MachineConnectedComponent {

  constructor(
    protected translateService: TranslateService,
    protected awsIotProviderService: AwsIotProviderService,
    protected sessionService: SessionService,
    protected snackBarService: SnackbarService,
    protected loaderService: LoaderService,
    protected modelService: ModelService
  ) {
    super(awsIotProviderService, sessionService, modelService);
  }

  machineSessionedStringSerial = '';
  modelSessioned = '';
  public showLoading: boolean = true;
  realtime: [any, any,any] = [null,null,null];

  protected connect() {
    this.realtime = [null, null,null];

    
    this.subscribe(FrigomatTopicsSub.currenStatus, this.statusArrived);
    this.subscribe(FrigomatTopicsSub.realtime, this.realTimeArrived);
    this.publish(FrigomatTopicsPub.recipeList);
    const numbers = timer(200);
    numbers.subscribe(x => {
      this.getStatus();
    });
  }

  getStatus() {
    this.publish(FrigomatTopicsPub.currenStatus);
    this.publish(FrigomatTopicsPub.realtime);
  }

  realTimeArrived(data: any, app: any) {
    const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];
    app.realtime[1] = data.value;
  }

  statusArrived(data: any, app: any) {
    const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]];
      app.realtime[2] = data.value;
  }

  stop(section: number) {
    this.publish(FrigomatTopicsPub.stopCmd);
  }

}
