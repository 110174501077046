export const environment = {
  production: true,
  url: 'https://wimanager.frigomat.com',
  urlApi: 'api',
  gestappUrls:['https://ice.gestapp.it'],
  aws:{
    identityPoolId:'eu-central-1:2462fff5-1f8f-4c1e-8eab-fddad9c6703d' , //TO BE REDEFINED
    region:'eu-central-1',
    userPoolId:'eu-central-1_ZkrB2kQPy', //TO BE REDEFINED
    userPoolWebClientId:'6osgdvt75hb9dhc7i6beetchum', //TO BE REDEFINED
    iotEndpoint:'a37qfp0ijxund6-ats.iot.eu-central-1.amazonaws.com'
  }
};
