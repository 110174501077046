import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FirmwareService } from 'src/app/services/firmware.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl:'./delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit {

  constructor(
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete() {
    this.data.service.delete(this.data.key,this.data.id).subscribe(
      (x) => {
        this.dialogRef.close();
        this.snackBarService.showSnackBar(
          this.translateService.instant("operation_successfully")
        );
        this.data.service.load();
      },
      (err) => {
        this.dialogRef.close();
        this.snackBarService.showSnackBar(
          this.translateService.instant("error") +
            ":" +
            this.translateService.instant(err.error)
        );

        console.log("Error delete firmware: ", err);
      }
    );
  }
}
