<mat-card class="mat-elevation-z8">
  <mat-tab-group mat-align-tabs="start">
    <mat-tab *ngFor="let sector of listSectors" [label]="sector">
      <app-parameter-list
        [sector]="sector"
        [modelloId]="machineSessioned.maC_ModelloSx"
        [numeroSerie]="machineSessioned.maC_Numero_Serie_MS"
      >
      </app-parameter-list>
    </mat-tab>
  </mat-tab-group>
</mat-card>
