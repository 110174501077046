<h1 mat-dialog-title>{{'edit_recipe' | translate}}</h1>
<div  [ngSwitch]="model.moD_Codice">
  <div *ngSwitchCase="3" [ngSwitch]="model.moD_Codice">
    <div mat-dialog-content>
      <app-recipe-soft-form  #child  [entity]="entity" [entityList]="entityList"></app-recipe-soft-form>  
    </div>
    <div mat-dialog-actions>
      <button [disabled]="child===undefined || !child.form.valid" (click)="update()" mat-raised-button color="primary" >{{ 'save' | translate }}</button>
      <button [disabled]="child===undefined || !child.form.valid" (click)="update(true)" mat-raised-button color="light-blue">{{ 'add_new' | translate }}</button>
      <button  (click) ="delete()" mat-raised-button color="warn">{{ 'delete' | translate }}</button>
      <button  color="primary" mat-button mat-dialog-close>{{ 'close' | translate }}</button>
    </div>  
  </div>
  <div *ngSwitchCase="4">
    <div mat-dialog-content>
      <app-recipe-soft-ht-form  #child  [entity]="entity" [entityList]="entityList"></app-recipe-soft-ht-form>  
    </div>
    <div mat-dialog-actions>
      <button [disabled]="child===undefined || !child.form.valid" (click)="update()" mat-raised-button color="primary" >{{ 'save' | translate }}</button>
      <button [disabled]="child===undefined || !child.form.valid" (click)="update(true)" mat-raised-button color="light-blue">{{ 'add_new' | translate }}</button>
      <button  (click) ="delete()" mat-raised-button color="warn">{{ 'delete' | translate }}</button>
      <button  color="primary" mat-button mat-dialog-close>{{ 'close' | translate }}</button>
    </div>  
  </div>
  <div *ngSwitchCase="5">
    <div mat-dialog-content>
      <app-recipe-twist-form  #child  [entity]="entity" [entityList]="entityList"></app-recipe-twist-form>  
    </div>
    <div mat-dialog-actions>
      <button [disabled]="!child.form.valid" (click)="update()" mat-raised-button color="primary" >{{ 'save' | translate }}</button>
      <button [disabled]="!child.form.valid" (click)="update(true)" mat-raised-button color="light-blue">{{ 'add_new' | translate }}</button>
      <button  (click) ="delete()" mat-raised-button color="warn">{{ 'delete' | translate }}</button>
      <button  color="primary" mat-button mat-dialog-close>{{ 'close' | translate }}</button>
    </div>  
  </div>
</div>