import { IModelObj } from "./iModelObj";

export interface IUteUser extends IModelObj {
    utE_Id: number;
    utE_Descrizione: string;
    utE_Nome: string;
    utE_Password: string;
    utE_Livello: number;
    utE_Abilitato: number;
    utE_Telefono1: string;
    utE_Email1: string;
    utE_Sede: string;
    utE_PartitaIva: string;
    utE_Informativa: number;
}

export class UteUser implements IUteUser{
    id: string | number;
    utE_Id: number;
    utE_Descrizione: string;
    utE_Nome: string;
    utE_Password: string;
    utE_Livello: number;
    utE_Abilitato: number;
    utE_Telefono1: string;
    utE_Email1: string;
    utE_Sede: string;
    utE_PartitaIva: string;
    utE_Informativa: number;

    constructor(props?: IUteUser) {
        if(props!=undefined){
            Object.keys(props).forEach(prop => {
                const value = props[prop];
                this[prop] = value;
            });
        }
        this.id = props && props.utE_Id || 0;
    }



}
