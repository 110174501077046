import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SessionService } from '../services/session.service';
import { Router} from '@angular/router';
import { MacMacchine } from '../objects/macMacchine';
import { CmdElencoComandi } from '../objects/cmdElencoComandi';
import { ModElencoModelli } from '../objects/modElencoModelli';
import { UteUser } from '../objects/uteUser';
import { FlvFlavour } from '../objects/flvFlavour';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FlavourService } from '../services/flavour.service';
import { ModelService } from '../services/model.service';
import { combineLatest, from } from 'rxjs';
import { CommandService } from '../services/command.service';
import { CommandHistoryService } from '../services/command-history.service';
import { ConfigUtil } from '../utils/configUtils';
import { map } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { CommandNamePipe } from '../utils/pipes/command-name.pipe';
import { FlavourNamePipe } from '../utils/pipes/flavour-name.pipe';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-commands',
  templateUrl: './commands.component.html',
  styleUrls: ['./commands.component.scss'],
})
export class CommandsComponent implements OnInit, OnDestroy {

  machineSessioned: MacMacchine = new MacMacchine();
  displayedColumns: string[] = ['command', 'scO_SxDx', 'scO_Peso', 'ricetta', 'scO_DateTime'];
  dataSourceCommands;
  commandsList: CmdElencoComandi[] = [];
  modelsList: ModElencoModelli[] = [];
  userLogged: UteUser;
  flavourArray: FlvFlavour[] = [];
  flavourTwinArray: FlvFlavour[] = [];
  modelListCod:number[]=[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  constructor(
    private sessionService: SessionService,
    private route: Router,
    private flavourService:FlavourService,
    private modelService:ModelService,
    private commandService:CommandService,
    private commandHistoryService:CommandHistoryService,
    private translateService:TranslateService
    ) {
      this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
    }

  ngOnInit() {


    if(this.machineSessioned.maC_ModelloSx!=null && this.machineSessioned.maC_ModelloSx!=0) 
    {
      this.modelListCod.push(this.machineSessioned.maC_ModelloSx);
    }
    if(this.machineSessioned.maC_ModelloCen!=null && this.machineSessioned.maC_ModelloCen!=this.machineSessioned.maC_ModelloSx && this.machineSessioned.maC_ModelloCen!=0)
    {
       this.modelListCod.push(this.machineSessioned.maC_ModelloCen);
    }
    if(this.machineSessioned.maC_ModelloDx!=null && this.modelListCod.findIndex(x=>x ==this.machineSessioned.maC_ModelloDx)<0 && this.machineSessioned.maC_ModelloDx!=0)
    {
       this.modelListCod.push(this.machineSessioned.maC_ModelloDx);
    }
  
    let flavourtwin$ = from([[]]);
    let flavour$ = this.flavourService.read(this.machineSessioned.maC_Numero_Serie_MS,this.modelListCod[0]);
    if(this.modelListCod.length>1){
      flavourtwin$ = this.flavourService.read(this.machineSessioned.maC_Numero_Serie_MS,this.modelListCod[1]);
    }

    const model$ = this.modelService.listObservable;  
    const command$ = this.commandService.listObservable;  
    const history$ = this.commandHistoryService.read(this.machineSessioned.maC_Numero_Serie_MS);
    
    combineLatest(flavour$,flavourtwin$, model$, command$,history$).pipe(
      map(([flavour$,flavourtwin$, model$, command$,history$]) => ({
        flavList: flavour$, 
        flavListTwin: flavourtwin$, 
        modelList: model$,
        commandList:command$,
        historyList:history$
      }))).subscribe(pair => {
      this.flavourArray = pair.flavList;
      this.flavourTwinArray = pair.flavListTwin;
      this.commandsList = pair.commandList;
      this.modelsList = pair.modelList;
      this.dataSourceCommands = new MatTableDataSource(pair.historyList);
      this.setCustomDataAccessor();
      this.dataSourceCommands.paginator = this.paginator;
      this.dataSourceCommands.sort = this.sort;

    });
  }

  setFlavourString(flavourId: number): string {
    try {
      const tmpFlavour: FlvFlavour = this.flavourArray.filter(x => x.index == flavourId)[0];
      return tmpFlavour.name;
    } catch (error) {
      try {
        return flavourId.toString();
      } catch (error) {
        return "-";
      }
    }
  }

  setCustomDataAccessor() {
    const commandPipe = new CommandNamePipe(this.sessionService);
    const flavourPipe = new FlavourNamePipe(this.sessionService,this.translateService);
    this.dataSourceCommands.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'command':
          return commandPipe.transform(item,this.commandsList,this.modelsList);
        case 'ricetta':
          return flavourPipe.transform(item,this.flavourArray,this.flavourTwinArray,this.modelListCod);
        default:
          return item[property];
      }
    };
  }

  applyFilter(filterValue: string) {
    this.dataSourceCommands.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    // destroy component on destroy
    this.dataSourceCommands = [];
    console.log("close commands")
    this.route.dispose();
  }
}
