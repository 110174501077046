import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor() { }

  get apiBaseUrl() {

    if (!environment.urlApi) {
      //throw Error('Config file not loaded!');
      return "http://localhost:9220/api/";
    }

    return environment.urlApi;
  }
}
