import { TypesNames } from "./../objects/dto-notification";
import { Component, OnInit } from "@angular/core";
import { NotificationSettingsService } from "../services/notification-settings.service";
import { AlarmService } from "../services/alarm.service";
import { CommandService } from "../services/command.service";
import { ParameterService } from "../services/parameter.service";
import { ModelService } from "../services/model.service";
import { ModElencoModelli } from "../objects/modElencoModelli";
import {
  DtoNotificationElement,
  DtoNotificationModel,
  DtoNotificationType,
} from "../objects/dto-notification";
import { MachineService } from "../services/machine.service";
import { combineLatest } from "rxjs";
import { NotNotificationSettings } from "../objects/notNotificationSettings";
import { SessionService } from "../services/session.service";
import { UserLogged } from "../objects/user-logged";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "../services/snackbar.service";
import { MacMacchine } from "../objects/macMacchine";

@Component({
  selector: "app-notification-settings",
  templateUrl: "./notification-settings.component.html",
  styleUrls: ["./notification-settings.component.scss"],
})
export class NotificationSettingsComponent implements OnInit {
  modelList: ModElencoModelli[];

  activeAll: boolean;
  modelNotificationList: DtoNotificationModel[] = [];
  unique: number[];

  userSessioned: UserLogged;
  loading: boolean;
  typeNames = TypesNames;

  constructor(
    private sessionService: SessionService,
    private machineSerice: MachineService,
    private modelService: ModelService,
    private alarmService: AlarmService,
    private commandService: CommandService,
    private parameterService: ParameterService,
    private notificationService: NotificationSettingsService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.userSessioned = this.sessionService.currentUserValue;
    combineLatest([
      this.machineSerice.read(),
      this.modelService.read(),
      this.alarmService.read(),
      this.commandService.read(),
      this.parameterService.read(),
    ]).subscribe(
      ([
        machineList,
        modelList,
        alarmList,
        commandList,
        parameterList,
      ]) => {
        const mapModIdMs: Map<number, MacMacchine> = new Map();
        machineList.forEach((mac) => {
          mapModIdMs.set(mac.maC_Numero_Serie_MS, mac);
        });
        //unique model id list fro the current user
        const uniqueDx = [...new Set(machineList.map((x) => x.maC_ModelloDx))];
        const uniqueSx = [...new Set(machineList.map((x) => x.maC_ModelloSx))];
        this.unique = [...uniqueDx, ...uniqueSx];
        let i: number = 0;
        modelList.forEach((model) => {
          if (model.moD_Codice > 0 && this.unique.indexOf(model.moD_Codice) > -1) {
            let tempAlarms = new DtoNotificationType();
            tempAlarms.activeType = false;
            tempAlarms.name = 1;
            tempAlarms.list = [];
            if (alarmList && alarmList.length > 0) {
              alarmList.forEach((a) => {
                if (a.alR_IdModello === model.moD_Codice) {
                  let alarm = new DtoNotificationElement();
                  alarm.active = false;
                  alarm.description = a.alR_Descrizione;
                  alarm.id = a.alR_Id;
                  tempAlarms.list.push(alarm);
                }
              });
            }
            let tempCommands = new DtoNotificationType();
            tempCommands.activeType = false;
            tempCommands.name = 2;
            tempCommands.list = [];
            if (commandList && commandList.length > 0) {
              commandList.forEach((c) => {
                if (c.cmD_IdModello === model.moD_Codice) {
                  let command = new DtoNotificationElement();
                  command.active = false;
                  command.description = c.cmD_Descrizione;
                  command.id = c.cmD_Id;
                  tempCommands.list.push(command);
                }
              });
            }
            let tempParameters = new DtoNotificationType();
            tempParameters.activeType = false;
            tempParameters.name = 3;
            tempParameters.list = [];
            if (parameterList && parameterList.length > 0) {
              parameterList.forEach((p) => {
                if (p.paR_IdModello === model.moD_Codice) {
                  let parameter = new DtoNotificationElement();
                  parameter.active = false;
                  parameter.description = p.paR_Descrizione;
                  parameter.id = p.paR_Id;
                  tempParameters.list.push(parameter);
                }
              });
            }

            let tempTypes = [];
            if (tempAlarms.list.length > 0) {
              tempAlarms.index = i;
              tempAlarms.list.sort((a, b) => {
                if (a.description < b.description) {
                  return -1;
                }
                else if (a.description > b.description) {
                  return 1;
                }
                else {
                  return 0;
                }
              });
              tempTypes.push(tempAlarms);
              i++;
            }
            if (tempCommands.list.length > 0) {
              tempCommands.index = i;
              tempCommands.list.sort((a, b) => {
                if (a.description < b.description) {
                  return -1;
                }
                else if (a.description > b.description) {
                  return 1;
                }
                else {
                  return 0;
                }
              });
              tempTypes.push(tempCommands);
              i++;
            }
            if (tempParameters.list.length > 0) {
              tempParameters.index = i;
              tempTypes.push(tempParameters);
              tempParameters.list.sort((a, b) => {
                if (a.description < b.description) {
                  return -1;
                }
                else if (a.description > b.description) {
                  return 1;
                }
                else {
                  return 0;
                }
              });
              i++;
            }
            if (model.moD_Codice !== 3 && model.moD_Codice !== 1 && model.moD_Codice !== 4 ) {
              let tempProductions = new DtoNotificationType();
              tempProductions.activeType = false;
              tempProductions.name = 4;
              tempProductions.list = [];
              tempProductions.index = i;
              tempTypes.push(tempProductions);
              i++;
            }

            let tempMod = new DtoNotificationModel();
            tempMod.model = model;
            tempMod.activeModel = false;
            tempMod.types = tempTypes;
            this.modelNotificationList.push(tempMod);
          }
        });
        console.log(this.modelNotificationList);
        this.modelNotificationList.sort((a, b) => {
          if (a.model.moD_Descrizione < b.model.moD_Descrizione) {
            return -1;
          }
          else if (a.model.moD_Descrizione > b.model.moD_Descrizione) {
            return 1;
          }
          else {
            return 0;
          }
        });

        this.notificationService.read().subscribe((notificationList) => {
          if (notificationList.length > 0) {
            notificationList.forEach((activeNot) => {
              if (activeNot.noT_MOD_Id) {
                this.modelNotificationList.forEach((not) => {
                  if (activeNot.noT_MOD_Id === not.model.moD_Id) {
                    if (activeNot.noT_Type) {
                      not.types.forEach((type) => {
                        if (activeNot.noT_Type === type.name) {
                          if (activeNot.noT_TypeID) {
                            type.list.forEach((element) => {
                              if (activeNot.noT_TypeID === element.id) {
                                element.active = true;
                              }
                            });
                          } else {
                            type.activeType = true;
                            type.list.forEach((element) => {
                              element.active = true;
                            });
                          }
                        }
                      });
                    } else {
                      not.activeModel = true;
                      not.types.forEach((type) => {
                        type.activeType = true;
                        type.list.forEach((element) => {
                          element.active = true;
                        });
                      });
                    }
                  }
                });
              } else {
                this.activeAll = true;
                this.modelNotificationList.forEach((mod) => {
                  mod.activeModel = true;
                  mod.types.forEach((type) => {
                    type.activeType = true;
                    type.list.forEach((element) => {
                      element.active = true;
                    });
                  });
                });
              }
            });
          }
        });
      }
    );
  }

  elementSelectionChanged(
    event,
    type: DtoNotificationType,
    model: DtoNotificationModel
  ) {
    console.log(event);
    if (!event.active) {
      type.activeType = false;
      model.activeModel = false;
      this.activeAll = false;
    }
    if (
      type.list.filter((x) => x.active === true).length === type.list.length
    ) {
      type.activeType = true;
    }
    if (
      model.types.filter((x) => x.activeType === true).length ===
      model.types.length
    ) {
      model.activeModel = true;
    }
    if (
      this.modelNotificationList.filter((x) => x.activeModel === true)
        .length === this.modelNotificationList.length
    ) {
      this.activeAll = true;
    }
  }

  typeSelectionChanged(
    event,
    type: DtoNotificationType,
    model: DtoNotificationModel
  ) {
    console.log("type", event);
    if (event.checked) {
      type.activeType = true;
      type.list.forEach((x) => (x.active = true));
      if (
        model.types.filter((x) => x.activeType === true).length ===
        model.types.length
      ) {
        model.activeModel = true;
      }
      if (
        this.modelNotificationList.filter((x) => x.activeModel === true)
          .length === this.modelNotificationList.length
      ) {
        this.activeAll = true;
      }
    } else {
      type.list.forEach((x) => (x.active = false));
      type.activeType = false;
      model.activeModel = false;
      this.activeAll = false;
    }
  }

  modelSelectionChanged(event, model: DtoNotificationModel) {
    model.types.forEach((type) => {
      type.activeType = event.checked;
      this.typeSelectionChanged(event, type, model);
    });
    if (!event.checked) {
      this.activeAll = false;
    }
    if (
      this.modelNotificationList.filter((x) => x.activeModel === true)
        .length === this.modelNotificationList.length
    ) {
      this.activeAll = true;
    }
  }

  allSelectionChanged(event) {
    this.modelNotificationList.forEach((model) => {
      model.activeModel = event.checked;
      this.modelSelectionChanged(event, model);
    });
  }
  save() {
    this.loading = true;

    let notificationSettingList = [];
    if (this.activeAll) {
      let ns = new NotNotificationSettings();
      ns.noT_UTE_Id = this.userSessioned.utE_Id;
      notificationSettingList.push(ns);
    } else {
      this.modelNotificationList.forEach((m) => {
        if (m.activeModel === true) {
          let ns = new NotNotificationSettings();
          ns.noT_UTE_Id = this.userSessioned.utE_Id;
          ns.noT_MOD_Id = m.model.moD_Id;
          notificationSettingList.push(ns);
        } else {
          m.types.forEach((t) => {
            if (t.activeType === true) {
              let ns = new NotNotificationSettings();
              ns.noT_UTE_Id = this.userSessioned.utE_Id;
              ns.noT_MOD_Id = m.model.moD_Id;
              ns.noT_Type = t.name;
              notificationSettingList.push(ns);
            } else {
              t.list.forEach((e) => {
                if (e.active === true) {
                  let ns = new NotNotificationSettings();
                  ns.noT_UTE_Id = this.userSessioned.utE_Id;
                  ns.noT_MOD_Id = m.model.moD_Id;
                  ns.noT_Type = t.name;
                  ns.noT_TypeID = e.id;
                  notificationSettingList.push(ns);
                }
              });
            }
          });
        }
      });
    }

    this.notificationService.createMulti(notificationSettingList).subscribe(
      () => {
        this.snackBarService.showSnackBar(
          this.translateService.instant("operation_successfully")
        );
      },
      (err) => {
        console.error(err);
        this.snackBarService.showSnackBar(
          this.translateService.instant("error") +
            ":" +
            this.translateService.instant(err.error)
        );
        this.loading = false;
      }
    );
  }
}
