import { Pipe, PipeTransform } from '@angular/core';
import { OnOffStatus } from '../enums/various';

@Pipe({
  name: 'oNoFF'
})
export class OnOffPipe implements PipeTransform {

  transform(value: any): any {
    return (value == true || value == OnOffStatus.On) ? "On" : "Off";
  }
}

