import { Pipe, PipeTransform } from '@angular/core';
import { AlrElencoAllarmi } from 'src/app/objects/alrAlarmsList';
import { MacMacchine } from 'src/app/objects/macMacchine';
import { ModElencoModelli } from 'src/app/objects/modElencoModelli';
import { SalStoricoAllarmi } from 'src/app/objects/salStoricoAllarmi';
import { SessionService } from 'src/app/services/session.service';
import { ConfigUtil } from '../configUtils';

@Pipe({
  name: 'alarmName'
})
export class AlarmNamePipe implements PipeTransform {
  machineSessioned: MacMacchine = new MacMacchine();
  constructor(public sessionService: SessionService) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  transform(value: SalStoricoAllarmi,alarmsList:AlrElencoAllarmi[], modelsList:ModElencoModelli[]): any {
    try {
      let idToPass = this.machineSessioned.maC_ModelloSx;
      // solo la GX è diversa DA tutte le altre, la filtro nell'if
      if(this.machineSessioned.maC_ModelloSx !== 2){
        // tytte le altre macchine
        idToPass = 0;
        if(value.saL_SxDx === 1){ idToPass = this.machineSessioned.maC_ModelloSx; }
        if(value.saL_SxDx === 2){ idToPass = this.machineSessioned.maC_ModelloDx!=null?this.machineSessioned.maC_ModelloDx:this.machineSessioned.maC_ModelloSx; }
        if(value.saL_SxDx === 3){ idToPass = this.machineSessioned.maC_ModelloCen!=null?this.machineSessioned.maC_ModelloCen:this.machineSessioned.maC_ModelloSx; }
      }
      

      let model = modelsList.find(x =>  x.moD_Codice === idToPass);
      console.log(model);
      const al = alarmsList.find(x => x.alR_IdAllarme === value.saL_Allarme && x.alR_IdModello === model.moD_Codice);
      return al.alR_Descrizione;
    } catch (error) {
      return "-";
    }
  }
}
