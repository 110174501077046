import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'payments'
})
export class PaymentsPipe implements PipeTransform {

  transform(paymentState: number): any {
    switch (paymentState) {
      case 0:
          return 'lightgray';
        break;
      case 1:
          return 'green';
        break;
      case 2:
          return 'yellow';
        break;
      case 3:
          return 'red';
        break;  
      default:        
          return 'lightgray';
        break;
    }
  }

}
