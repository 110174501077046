import { Injectable } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthCognitoService {

  constructor() {
    Amplify.configure({
      Auth:{
        identityPoolId: environment.aws.identityPoolId,
        region:environment.aws.region,
        userPoolId:environment.aws.userPoolId,
        userPoolWebClientId: environment.aws.userPoolWebClientId
      },
    })
  }

  signIn(username:string, password:string){
    Auth.signIn(username,password).then(data => {
      console.log('hello Cognito'+data);
    });
  }
}
