import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParElencoParametri } from '../objects/parElencoParametri';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParameterService extends GenericCrudService<ParElencoParametri,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"ParametersList");
   this.load();
  }

  readCustom(modId): Observable<ParElencoParametri[]> {
    //this.loading = true;

    console.log(this.apiName);
    return this.http.get<ParElencoParametri[]>(`${this.endpoint}/${this.apiName}/custom/${modId}`);
  }


}
