import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MachineConnectedComponent } from 'src/app/machine-connected/machine-connected';
import { Recipe, RecipeTwist } from 'src/app/objects/recipe';
import { AwsIotProviderService } from 'src/app/services/aws-iot-provider.service';
import { ModelService } from 'src/app/services/model.service';
import { SessionService } from 'src/app/services/session.service';
import { FrigomatTopicsPub } from 'src/app/utils/enums/frigomatTopics';
import { RecipeType } from 'src/app/utils/enums/recipeType';
import { RecipeFormComponent } from '../recipe-form';


@Component({
  selector: 'app-recipe-twist-form',
  templateUrl: './recipe-twist-form.component.html',
  styleUrls: ['./recipe-twist-form.component.scss']
})
export class RecipeTwistFormComponent extends RecipeFormComponent<RecipeTwist> {
  
  @Input() entity:RecipeTwist;
  @Input() entityList:RecipeTwist[];
  @Output() protected onFormChange = new EventEmitter<any>();
 
  recipeTypeList:RecipeType[]  = [RecipeType.Auto,RecipeType.AutoHard,RecipeType.SemiAuto,RecipeType.SemiAutoTime,RecipeType.Slush,RecipeType.CoffeeSlush];
  
  constructor(
    protected fb: FormBuilder,
    protected translateService:TranslateService,
    protected awsIotProviderService:AwsIotProviderService,
    protected sessionService:SessionService,
    protected modelService:ModelService
  ) { 
    super(fb,awsIotProviderService, sessionService, modelService);
  }

  ngOnInit() {
    this.isNew = this.entity==null;
    this.entityTemp = new RecipeTwist();
    Object.assign(this.entityTemp,this.entity);
  
    this.form = this.fb.group({
      recipe_nameCtrl: [this.isNew?'':this.entity.name, Validators.compose([Validators.required,  (c)=> this.newRecipeValidator(c,this.entityList,this.entity)])],
      recipe_typeCtrl: [this.isNew?0:this.entity.type,Validators.required],
      recipe_turboCtrl: [this.isNew || this.entity.turbo==null?RecipeTwist.TurboMin:this.entity.turbo,Validators.compose([Validators.required,Validators.max(RecipeTwist.TurboMax),Validators.min(RecipeTwist.TurboMin)])],
      recipe_consistencyCtrl: [this.isNew || this.entity.consistency==null?RecipeTwist.ConsistencyMin:this.entity.consistency,Validators.compose([Validators.required,Validators.max(RecipeTwist.ConsistencyMax),Validators.min(RecipeTwist.ConsistencyMin)])],
      recipe_durationCtrl:  [this.isNew || this.entity.duration==null?RecipeTwist.DurationMin:this.entity.duration,Validators.compose([Validators.required,Validators.max(RecipeTwist.DurationMaxSemiAuto),Validators.min(RecipeTwist.DurationMin)])],
      recipe_idCtrl: this.isNew?'':this.entity.id,
      });

      this.form.get('recipe_typeCtrl').valueChanges
      .subscribe(() =>{ 
        this.entityTemp.type = this.form.get('recipe_typeCtrl').value as RecipeType;
        this.form.controls['recipe_consistencyCtrl'].setValidators(Validators.compose([Validators.required,Validators.max(this.ConsistencyMax),Validators.min(this.ConsistencyMin)]));
        this.entityTemp.consistency = RecipeTwist.ConsistencyMin;
        this.form.controls['recipe_consistencyCtrl'].setValue( this.entityTemp.consistency);
        this.entityTemp.turbo = RecipeTwist.TurboMin;
        this.form.controls['recipe_turboCtrl'].setValue(RecipeTwist.TurboMin);
        this.form.controls['recipe_durationCtrl'].setValidators(Validators.compose([Validators.required,Validators.max(this.DurationMax),Validators.min(RecipeTwist.DurationMin),stepValidator(5)]));
        this.entityTemp.duration = RecipeTwist.DurationMin;
        this.form.controls['recipe_durationCtrl'].setValue(RecipeTwist.DurationMin);
        if(this.entityTemp.showConsistency){

          this.form.controls['recipe_consistencyCtrl'].setValidators(Validators.compose([Validators.required,Validators.max(this.ConsistencyMax),Validators.min(this.ConsistencyMin)]));
          this.entityTemp.consistency = this.entityTemp.type==RecipeType.Slush ?RecipeTwist.ConsistencyMinSlush: RecipeTwist.ConsistencyMin;
          this.form.controls['recipe_consistencyCtrl'].setValue( this.entityTemp.consistency);
          
        }
        this.onFormChange.emit(this.form.value)
      });
      this.onFormChange.emit(this.form.value);
  }

  public get DurationMin(){
    return RecipeTwist.DurationMin;
  }

  public get DurationMax(){
    if(this.form.get('recipe_typeCtrl').value==RecipeType.CoffeeSlush)
    return RecipeTwist.DurationMaxSlush;
    else
    return RecipeTwist.DurationMaxSemiAuto
  }

  public get TurboMin(){
    return RecipeTwist.TurboMin;
  }

  public get TurboMax(){
    return RecipeTwist.TurboMax;
  }

  public get ConsistencyMax():number {
    if(this.form.get('recipe_typeCtrl').value==RecipeType.Slush)
    return RecipeTwist.ConsistencyMaxSlush;
    else
    return RecipeTwist.ConsistencyMax
  }

  public get ConsistencyMin():number {
    if(this.form.get('recipe_typeCtrl').value==RecipeType.Slush)
    return RecipeTwist.ConsistencyMinSlush;
    else
    return RecipeTwist.ConsistencyMin
  }

  

  update(isNew:boolean=false){
    try{
    let recipe = new RecipeTwist();
    recipe.id=isNew?undefined:this.form.get('recipe_idCtrl').value;
    recipe.type=this.form.get('recipe_typeCtrl').value;
    recipe.name=this.form.get('recipe_nameCtrl').value;
    recipe.consistency= recipe.showConsistency? this.form.get('recipe_consistencyCtrl').value:undefined;
    recipe.duration= recipe.showDuration? this.form.get('recipe_durationCtrl').value:undefined;
    recipe.turbo= recipe.showTurbo? this.form.get('recipe_turboCtrl').value:undefined;
  
    this.publish(isNew?FrigomatTopicsPub.addRecipe:FrigomatTopicsPub.editRecipe,recipe);
      return true;
    }
    catch{
      return false;
    }
  }

  delete(){
    try{
    let recipe = new RecipeTwist();
    recipe.id=this.form.get('recipe_idCtrl').value;
    recipe.type=this.form.get('recipe_typeCtrl').value;
    recipe.name=this.form.get('recipe_nameCtrl').value;
    recipe.consistency= recipe.showConsistency? this.form.get('recipe_consistencyCtrl').value:undefined;
    recipe.duration= recipe.showDuration? this.form.get('recipe_durationCtrl').value:undefined;
    recipe.turbo= recipe.showTurbo? this.form.get('recipe_turboCtrl').value:undefined;
  
    this.publish(FrigomatTopicsPub.deleteRecipe,recipe);
      return true;
    }
    catch{
      return false;
    }
  }

  
}

export function stepValidator(step:number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const oddmentNull = (Number(control.value)%step)!==0;
    return oddmentNull ? {oddment: {value: control.value}} : null;
  };
}
