import { Component, OnInit, ViewChild } from '@angular/core';
import { SessionService } from '../services/session.service';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';
import { MacMacchine } from '../objects/macMacchine';
import { HttpClient } from '@angular/common/http';
import { UteUser } from '../objects/uteUser';
import { SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ModifyuserModalComponent } from '../modals/modifyuser-modal/modifyuser-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserLogged } from '../objects/user-logged';
import { UserService } from '../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RolePipe } from '../utils/pipes/role.pipe';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = ['utE_Nome', 'utE_Descrizione', 'password', 'utE_Livello', 'utE_Abilitato', 'actions'];
  dataSource: MatTableDataSource<UteUser> = new MatTableDataSource<UteUser>();

  loading = true;
  userSessioned: UserLogged;
  form: FormGroup;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private sessionService: SessionService,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private userService: UserService,
    protected fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.userSessioned = this.sessionService.currentUserValue;
    this.userService.listObservable.subscribe(
      (data: any[]) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.sort.direction='asc';
        this.dataSource.sort = this.sort;
        this.setCustomDataAccessor();
        this.loading = false;
        },
      error => console.log('error'),
      ()=>console.log('completeLoad')
    );
    this.loading = true;
    this.userService.load();

    this.form = this.fb.group({
      ute_levelCtrl:['',Validators.required],
      ute_usernameCtrl:['',Validators.required],
      ute_passwordCtrl:['',Validators.required],
      ute_descriptionCtrl:['',Validators.required],
      ute_enabledCtrl:[1,Validators.required],
    });
  }

  addUser() {
      let userTemp: UteUser ={
        id:undefined,
        utE_Id:undefined,
        utE_Nome: this.form.get('ute_usernameCtrl').value,
        utE_Descrizione:this.form.get('ute_descriptionCtrl').value,
        utE_Livello: this.form.get('ute_levelCtrl').value,
        utE_Abilitato: this.form.get('ute_enabledCtrl').value,
        utE_Telefono1: undefined,
        utE_Email1: undefined,
        utE_Sede: undefined,
        utE_PartitaIva: undefined,
        utE_Password:this.form.get('ute_passwordCtrl').value,
        utE_Informativa: undefined
      }

      this.userService.create(userTemp).subscribe(x=>{
        this.snackBarService.showSnackBar(this.translateService.instant('operation_successfully'));
        this.userService.load();
      }, err => {
        this.snackBarService.showSnackBar(this.translateService.instant('error')+':'+this.translateService.instant(err.error));

        console.log('Error add user: ', err);
      })
  }

  setCustomDataAccessor() {
    const rolePipe = new RolePipe(this.translateService);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'utE_Livello':
          return rolePipe.transform(item.utE_Livello);
        case 'utE_Abilitato':
            return this.translateService.instant(item.utE_Abilitato?'enabled':'disabled');
        default:
          return item[property];
      }
    };
  }

  modifyUser(user: UteUser) {
    let userTemp: UteUser ={
      id:user.utE_Id,
      utE_Id:user.utE_Id,
      utE_Nome: user.utE_Nome,
      utE_Descrizione:user.utE_Descrizione,
      utE_Livello: user.utE_Livello,
      utE_Abilitato: user.utE_Abilitato,
      utE_Telefono1: user.utE_Telefono1,
      utE_Email1: user.utE_Email1,
      utE_Sede: user.utE_Sede,
      utE_PartitaIva: user.utE_PartitaIva,
      utE_Password:user.utE_Password,
      utE_Informativa:user.utE_Informativa
    }

    const modalRef = this.dialog.open(ModifyuserModalComponent, {
      data: user
    });

    modalRef.afterClosed().subscribe(res => {
      if(res){
        this.loading = true;
        this.userService.load();
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
