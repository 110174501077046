export class RgsRegistrazione {
    rgS_Id: number;
    rgS_DateTime: string;
    rgS_MAC_Numero_Serie_MS: number;
    rgS_Contacolpi_Sx: number;
    rgS_Ore_Compressore_Sx: number;
    rgS_Ore_Agitazione_C_Sx: number;
    rgS_Ore_Agitazione_V_Sx: number;
    rgS_Ore_Pompa_Sx: number;
    rgS_Ore_Accensione_Sx: number;
    rgS_Ore_Lavaggio_Sx: number;
    rgS_Contacolpi_Dx: number;
    rgS_Ore_Compressore_Dx: number;
    rgS_Ore_Agitazione_C_Dx: number;
    rgS_Ore_Agitazione_V_Dx: number;
    rgS_Ore_Pompa_Dx: number;
    rgS_Ore_Accensione_Dx: number;
    rgS_Ore_Lavaggio_Dx: number;
    rgS_Contacolpi_Mix: number;
    rgS_MCC?: number;
    rgS_MNC?: number;
    rgS_LAC?: number;
    rgS_CID?: number;
    rgS_ContacolpiCen?: number;
    rgS_Ore_Compressore_Cen?: number;
    rgS_Ore_Agitazione_C_Cen?: number;
    rgS_Ore_Agitazione_V_Cen?: number;
    rgS_Ore_Pompa_Cen?: number;
    rgS_Ore_Accensione_Cen?: number;
    rgS_Ore_Lavaggio_Cen?: number;
    rgS_Contacolpi_Mix2?: number;
    rgS_ICCID?: string;
  }
