<div class="login-container">
  <div class="login-background">
    <img src="../../assets/img/hologram.svg">
  </div>
  <div class="login-form-container">
    <img src="../../assets/img/logo.png">
    <mat-card>
      <div>
        <mat-menu #appMenu="matMenu">
            <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language.lng)"><mat-icon icon="language">translate</mat-icon> {{language.lngDesc}}</button>
        </mat-menu>
        <button mat-raised-button [matMenuTriggerFor]="appMenu"><mat-icon>language</mat-icon> {{ 'lingua' | translate }}</button>
      </div>
      <div>
        <h3>{{ 'effettua_login' | translate }}</h3>
        <p>{{ 'login_text_enter' | translate }}</p>
      </div>
      <mat-form-field>
        <input matInput placeholder="Username" [(ngModel)]="userCredential.username" (keydown.enter)="passwordMatInputHash.focus()" autofocus>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Password" #passwordMatInputHash type="password" (keydown.enter)="doLogin()" [(ngModel)]="userCredential.password">
      </mat-form-field>
      <mat-checkbox [(ngModel)]="userCredential.remember_credentials">{{ 'remember_password' | translate }}</mat-checkbox>
      <mat-progress-bar *ngIf="showLoading" mode="indeterminate"></mat-progress-bar>
      <button *ngIf="!showLoading" mat-raised-button color="primary" (click)="doLogin()">{{ 'accedi' | translate }}</button>
      <button mat-button (click)="forgetPassword()">{{ 'forgot_password' | translate }}</button>
    </mat-card>

  </div>
  <div class="version" >
    {{'version' | translate}}: {{version}}&nbsp;  <a routerLink="/privacy" target="_blanck"> {{'privacy' | translate}}</a>
  </div>
</div>
