import { Component } from '@angular/core';
import { MqttService } from 'ngx-mqtt';

import { SetSetpointParameters } from 'src/app/objects/setSetpointParameters';
import { CommandService } from 'src/app/services/command.service';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { RealtimeLcdComponent } from '../realtime-lcd/realtime-lcd.component';

@Component({
  selector: 'app-realtime-soft',
  templateUrl: './realtime-soft.component.html',
  styleUrls: ['./realtime-soft.component.scss']
})
export class RealtimeSoftComponent extends RealtimeLcdComponent {

  constructor(
    protected mqttService: MqttService,
    protected sessionService: SessionService,
    protected snackBarService: SnackbarService,
    protected commandService: CommandService
    ) {
      super(mqttService ,sessionService,snackBarService,commandService);
    }
}