import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlrElencoAllarmi } from '../objects/alrAlarmsList';
import { AppConfigService } from './app-config.service';
import { GenericCrudService } from './generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AlarmService extends GenericCrudService<AlrElencoAllarmi,number>{

  constructor(http: HttpClient,
    appConfigService: AppConfigService) {
   super(http ,appConfigService,"AlarmsList");
   this.load();
  }
  
}
