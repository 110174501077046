import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UteUser } from 'src/app/objects/uteUser';
import { EmaMachineList } from 'src/app/objects/emaMachineList';
import { AumAssociationUserMachine } from 'src/app/objects/aumAssociationUserMachine';
import { UserService } from 'src/app/services/user.service';
import { EmaService } from 'src/app/services/ema.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRole } from 'src/app/utils/enums/userRole';
import { AssociationService } from 'src/app/services/association.service';

@Component({
  selector: 'app-editassociation-modal',
  templateUrl: './editassociation-modal.component.html',
  styleUrls: ['./editassociation-modal.component.scss'],
})
export class EditassociationModalComponent implements OnInit {

  // @Input() data: MacMacchine;
  userList: UteUser[];
  machinesModelsList: EmaMachineList[] = [];
  tmpMachine: AumAssociationUserMachine = new AumAssociationUserMachine();
  dataTmp: AumAssociationUserMachine;
  showCombinata = false;
  form: FormGroup;
  
  gestoreInput;
  gestoreDescInput: string = "";
  modelloInput;
  modelloInputType2;
  public modeselect = 'Domain';

  constructor(
    public dialogRef: MatDialogRef<EditassociationModalComponent>,
    private snackBarService: SnackbarService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AumAssociationUserMachine,
    private userService:UserService,
    private emaService: EmaService,
    protected fb: FormBuilder,
    private associationService:AssociationService
  ) {
    
  }

  public get userRole(): typeof UserRole {
    return UserRole; 
  }

  ngOnInit() {
    this.userService.listObservable.subscribe(
      (data: UteUser[]) => {
        this.userList = data;
        },
      error => console.log('error'),
      ()=>console.log('completeLoad')
    );

    this.emaService.listObservable.subscribe(
      (data: EmaMachineList[]) => {
        this.machinesModelsList = data;
        },
      error => console.log('error'),
      ()=>console.log('completeLoad')
    );

    this.dataTmp = this.data;
    console.log('DATA received: ', this.dataTmp);
    this.tmpMachine = this.dataTmp;;
    
    
    // tslint:disable-next-line: max-line-length
    // filtro i modelli per la macchina che ha cliccato, se non c'è il modello impostato (la macchina non ha ancora comunicato col portale) metto 0 l'array
    if (this.tmpMachine.maC_ModelloSx === 0 || this.tmpMachine.maC_ModelloSx === null || this.tmpMachine.maC_ModelloSx === undefined) {
      this.machinesModelsList = [];
    } else {
      // this.modelList = data.modelList.filter(x => x.emaModelType === this.tmpMachine.macModelloSx);
      // const machineSx = this.data.modelList.filter(x => x.emaId === this.tmpMachine.macModelloSx)[0];
      const machineSx = this.machinesModelsList.filter(x => x.emA_Id === this.tmpMachine.maC_Macchina)[0];

      try {
        if(this.tmpMachine.maC_ModelloDx === 0 || this.tmpMachine.maC_ModelloDx === null || this.tmpMachine.maC_ModelloDx === undefined || this.tmpMachine.maC_ModelloDx===this.tmpMachine.maC_ModelloSx ){
          this.machinesModelsList = this.machinesModelsList.filter(x => x.emA_ModelType === this.tmpMachine.maC_ModelloSx);
        } else {
          this.machinesModelsList = this.machinesModelsList.filter(x => x.emA_ModelType === this.tmpMachine.maC_ModelloSx && x.emA_ModelType2 === this.tmpMachine.maC_ModelloDx);
        }
      } catch (error) {
        this.machinesModelsList = this.machinesModelsList.filter(x => x.emA_Descrizione !== '-');
      }

    }

    this.form = this.fb.group({
      aum_addressCtrl: this.tmpMachine.maC_Indirizzo,
      aum_modelCtrl: [this.tmpMachine.maC_Macchina,Validators.required],
      aum_dealerCtrl:[this.tmpMachine.dealerId],
      aum_customerCtrl:[this.tmpMachine.customerId, Validators.required],
      aum_techsCtrl:[this.tmpMachine.listTechId],
      aum_gramsCtrl:[this.tmpMachine.maC_Grammi,Validators.required],
      aum_paymentCtrl:[this.tmpMachine.maC_Pagamento!=null?this.tmpMachine.maC_Pagamento:0,Validators.required],
      });
  }

  save() {

    var aum: AumAssociationUserMachine = {
      id:this.tmpMachine.maC_Numero_Serie_MS,
      dealerId: this.form.get('aum_dealerCtrl').value,
      customerId: this.form.get('aum_customerCtrl').value,
      listTechId: this.form.get('aum_techsCtrl').value,
      maC_Numero_Serie_MS: this.tmpMachine.maC_Numero_Serie_MS,
      maC_Matricola: this.tmpMachine.maC_Matricola,
      maC_Macchina: this.form.get('aum_modelCtrl').value,
      maC_LastUpdate: new Date(),
      maC_Pagamento: this.form.get('aum_paymentCtrl').value,
      maC_Grammi: Number(this.form.get('aum_gramsCtrl').value),
      maC_Indirizzo: this.form.get('aum_addressCtrl').value,
      maC_BloccoSx:this.tmpMachine.maC_BloccoSx,
      maC_BloccoDx:this.tmpMachine.maC_BloccoDx,
      maC_VersSwInt:this.tmpMachine.maC_VersSwInt,
      maC_VersSwQSx:this.tmpMachine.maC_VersSwQSx,
      maC_VersSwVSx:this.tmpMachine.maC_VersSwVSx,
      maC_ModelloSx:this.tmpMachine.maC_ModelloSx,
      maC_VersSwQDx:this.tmpMachine.maC_VersSwQDx,
      maC_VersSwVDx:this.tmpMachine.maC_VersSwVDx,
      maC_ModelloDx:this.tmpMachine.maC_ModelloDx
    };
   
    console.log("Add modify: ", this.tmpMachine);

    this.associationService.update(aum).subscribe(
      (data) => {
        this.snackBarService.showSnackBar(this.translateService.instant("operation_successfully"));
        this.dialogRef.close(true);
      },
      error => {
        console.log(error)
        this.snackBarService.showSnackBar(this.translateService.instant("operation_warning"));
      }
    );
  }
}
