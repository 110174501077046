// import menu voices
import * as menuVoiceJson from '../assets/menus/menu.json';

import { Component, HostListener, NgZone } from '@angular/core';
import { MacMacchine } from './objects/macMacchine';
import { LanguageService } from './services/language.service';
import { Router, NavigationEnd } from '@angular/router';
import { SessionService } from './services/session.service';
import { WindowHandleService } from './services/window-handle.service';
import { LoaderService } from './services/loader.service';
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { AboutModalComponent } from './modals/about-modal/about-modal.component';
import { UserLogged } from './objects/user-logged';
import { UserRole } from './utils/enums/userRole';
import { ModelService } from './services/model.service';
import { ModElencoModelli } from './objects/modElencoModelli';
import { TranslateService } from '@ngx-translate/core';
import { version } from '../../package.json';
import { environment } from 'src/environments/environment';
import { CredentialSession } from './objects/userSession';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PolicyModalComponent } from './modals/policy-modal/policy-modal.component';
import { TokenService } from './services/token.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  menuOpen = false;
  menuModeOpen = 'side';
  showLogo = false;
  languages;
  menuVoices = [];
  showLoading = false;
  showLeftMenuAndTopBar = false;
  pageUrl = '';
  topbarMachineSerial = '';
  machineSessioned: MacMacchine = new MacMacchine();
  userSessioned: UserLogged;
  showCounterMenu = false;
  showTrattamentiMenu = false;
  showFlavours = false;
  showRecipe = false;
  showAnalisys = false;
  recipeListLabel: string;
  listModels: ModElencoModelli[] = [];
  public version: string = version;
  public dialogRef: MatDialogRef<PolicyModalComponent>
  showNotificationSettings: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.detectScreenWidth(event.target.innerWidth);
  }

  @HostListener('window:message', ['$event'])
  onReceiveMessage(event) {
    if (environment.gestappUrls.includes(event.origin)) {
      console.log('message received from ' + event.origin);
      let a = JSON.parse(event.data);
      let cs = new CredentialSession();
      cs.username = a.credentialSessioned.username;
      cs.password = a.credentialSessioned.password;
      cs.remember_credentials = a.credentialSessioned.remember_credentials;
      this.sessionService.setCredentialSession(cs)
      let user = new UserLogged(a.userSessioned.token);
      this.sessionService.setLoginSession(user);
      window.location.reload();
    }
  }

  constructor(
    private languageService: LanguageService,
    private loadingService: LoaderService,
    private sessionService: SessionService,
    private route: Router,
    private windowService: WindowHandleService,
    private modelService: ModelService,
    private translateService: TranslateService,
    private tokenService: TokenService,
    public dialog: MatDialog
  ) {
    // set menu
    this.setMenu();

    // this.initializeApp();
    this.detectScreenWidth(window.innerWidth);


    // set default language and set menu languages
    this.languageService.setDefaultLanguageAtStart();
    this.languages = this.languageService.getLanguages();

    // got behaviors object
    this.loadingService.isLoading.subscribe(res => {
      this.showLoading = res > 0;
    });

    this.modelService.listObservable.subscribe(
      (data: any[]) => {
        this.listModels = data;
        this.checkShowFlavours();
      },
      error => console.log('error'),
      () => console.log('completeLoad')
    )


    // got machine object
    this.sessionService.currentMachineSubject.subscribe(res => {
      this.machineSessioned = res;
      this.checkShowFlavours();
      if (res !== null) {
        this.topbarMachineSerial = res.maC_Matricola.toString();
      } else {
        this.topbarMachineSerial = '-';
      }

      // check menu voices shows
      if (this.machineSessioned !== undefined && this.machineSessioned !== null) {
        if (this.machineSessioned.maC_ModelloSx === 3 ||
          this.machineSessioned.maC_ModelloSx === 4 ||
          this.machineSessioned.maC_ModelloSx === 1) {
          this.showCounterMenu = true;
          this.showAnalisys = false;
        } else {
          this.showCounterMenu = false;
          this.showAnalisys = true;
        }
      }

      if (this.machineSessioned !== undefined && this.machineSessioned !== null) {
        if (this.machineSessioned.maC_ModelloSx === 4 || this.machineSessioned.maC_ModelloSx === 7) {
          this.showTrattamentiMenu = true;
        } else {
          this.showTrattamentiMenu = false;
        }
      }


      if (this.machineSessioned !== undefined && this.machineSessioned !== null) {
        if (this.machineSessioned.maC_ModelloSx === 3 ||
          this.machineSessioned.maC_ModelloSx === 4 ||
          this.machineSessioned.maC_ModelloSx === 1) {
          this.showTrattamentiMenu = true;
        } else {
          this.showTrattamentiMenu = false;
        }
      }
      // end menu voices

      this.setMenu();
    });


    // control to show/hide topbar
    this.windowService.showTopBarBehavior.subscribe(res => {
      this.showLeftMenuAndTopBar = res;
    });

    this.route.events.subscribe((event: NavigationEnd) => {
      this.pageUrl = event.urlAfterRedirects;
      if (this.pageUrl !== undefined) {
        if (this.pageUrl === '/login' || this.pageUrl === '/privacy') {
          this.menuOpen = false;
          this.showLeftMenuAndTopBar = false;
        } else {
          if (window.innerWidth > 1024) {
            this.menuOpen = true;
          }
          this.showLeftMenuAndTopBar = true;
          this.checkPolicyAgreement();
          // check token to show notification page
          this.checkToken();

        }
      }
    });
  }

  public get userRole(): typeof UserRole {
    return UserRole;
  }

  checkShowFlavours() {
    if (this.userSessioned == null) {
      this.showFlavours = false;
      this.showRecipe = false;
      this.showAnalisys = false;
    }
    else if (this.userSessioned.utE_Livello == this.userRole.Technician) {
      this.showFlavours = false;
      this.showRecipe = false; this.showAnalisys = false;
    }
    else if (this.machineSessioned !== undefined && this.machineSessioned !== null) {
      var model = this.listModels.find(x => x.moD_Codice == this.machineSessioned.maC_ModelloSx)
      if (model != null && model != undefined) {
        this.recipeListLabel = this.translateService.instant('recipe_list');
        if (model.moD_RMT) {
          this.showFlavours = [2, 3].findIndex(x => model.moD_RMT.rmT_Code == x) > -1;
          this.showRecipe = model.moD_RMT.rmT_Code === 4;
          if (model.moD_RMT !== null && model.moD_RMT.rmT_Code == 2) {
            this.recipeListLabel = this.translateService.instant('flavours');
          };
        }
      }
      else {
        this.showFlavours = false;
        this.showRecipe = false;
      }
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.userSessioned = this.sessionService.currentUserValue;
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    // check if user is logged, if not, the user will send on login

    // check machine sessioned
    this.machineSessioned = this.sessionService.currentMachineValue;

    this.sessionService.currentUserSubject.subscribe(res => {
      this.userSessioned = this.sessionService.currentUserValue;
    })

  }

  setMenu() {
    this.menuVoices = (menuVoiceJson as any).default;
    this.machineSessioned = this.sessionService.currentMachineValue;
    if (this.machineSessioned === null) {
      this.menuVoices = this.menuVoices.filter(x => x.url === '/dashboard');
    } else {
      this.menuVoices = (menuVoiceJson as any).default;
    }
  }

  openDialog() {
    this.dialog.open(LogoutModalComponent, {
      width: 'auto'
    });
  }

  openAboutDialog() {
    this.dialog.open(AboutModalComponent, {
      width: '500px',
    });
  }

  setLanguage(lng: string) {
    this.languageService.setLanguage(lng);
  }

  detectScreenWidth(w: number) {
    if (w < 1024 && this.pageUrl !== '/login') {
      this.menuOpen = false;
      this.menuModeOpen = 'over';
      this.showLogo = false;
    } else {
      if (this.pageUrl !== '/login' && this.route.url !== '/login') {
        this.menuOpen = true;
        this.menuModeOpen = 'side';
        this.showLogo = true;
      }
    }
  }


  goToFrigomatWebSite() {
    window.open('https://www.frigomat.com', "_blank");
  }

  checkPolicyAgreement() {
    if (this.userSessioned?.utE_Informativa == 0) {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
      this.dialogRef = this.dialog.open(PolicyModalComponent, {
        height: '90%',
        width: '80%',
        disableClose: true,
        data: {
          userSessioned: this.userSessioned
        }
      });
    }
  }

  checkToken() {
    if (this.userSessioned != null) {
      this.tokenService.readByUser(this.userSessioned.utE_Id).subscribe(
        tokenList => {
          if (tokenList.length > 0) {
            this.showNotificationSettings = true;
          } else {
            this.showNotificationSettings = false;
          }
        },
        error => {
          this.showNotificationSettings = false;
        }
      )
    }
  }
}
