import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecipeType } from '../enums/recipeType';

@Pipe({
  name: 'recipeTwist'
})
export class RecipeTwistPipe implements PipeTransform {

  constructor(protected translateService: TranslateService,
    ) {
  }

  transform(value: number):string {
    return this.translateService.instant("recipe_type_twist."+RecipeType[value])
  }

}
