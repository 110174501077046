import { Injectable, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SingleunitComponent } from 'src/app/singleunit/singleunit.component';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SingleunitGuard implements CanActivate {
  
@ViewChild(SingleunitComponent,{static: true}) singleunitChild: SingleunitComponent;

  constructor(
    private route: Router
  ){}

  canActivate(): boolean {
    console.log("canguard singleunit")
    this.route.dispose();
    timeout(500);
    return true;
  }
}
