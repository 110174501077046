import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FlvFlavour } from '../objects/flvFlavour';
import { ConfigUtil } from '../utils/configUtils';
import { SessionService } from '../services/session.service';
import { MacMacchine } from '../objects/macMacchine';
import { ModelService } from '../services/model.service';
import { FlavourService } from '../services/flavour.service';
import { ModElencoModelli } from '../objects/modElencoModelli';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '../services/snackbar.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-flavour-list',
  templateUrl: './flavour-list.component.html',
  styleUrls: ['./flavour-list.component.scss'],
})
export class FlavourListComponent implements OnInit {

  machineSessioned: MacMacchine;
  displayedColumns: string[] = ['index', 'locked', 'name'];
  dataSourceFlavours: MatTableDataSource<AbstractControl>;
  title:string="";
  singleLabel:string="";
  savedRecipeMessage:string="";
  form: FormGroup;
  model:ModElencoModelli;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Input() modCode: number=null;
  @Output() modelName = new EventEmitter<string>();
  
  constructor(
    private sessionService: SessionService,
    private modelService:ModelService,
    private flavourService:FlavourService,
    private translateService:TranslateService,
    private snackBarService:SnackbarService,
    private _formBuilder: FormBuilder
  ) {
    this.machineSessioned = JSON.parse(this.sessionService.getGenericSession(ConfigUtil.machineSessioned));
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      recipes: this._formBuilder.array([])
    });

    this.machineSessioned = this.sessionService.currentMachineValue;
    
    const flavour$ = this.flavourService.read(this.machineSessioned.maC_Numero_Serie_MS,this.modCode);
    const model$ = this.modelService.listObservable;  

    combineLatest(flavour$, model$, (flavList, modelList) => ({flavList, modelList}))
    .subscribe(pair => {
      let flavourSaved = pair.flavList;

      let model = pair.modelList.find(x=>x.moD_Codice==this.modCode);
      this.modelName.emit(model.moD_Descrizione);
      this.title = this.translateService.instant(model.moD_RMT.rmT_Code==2?'flavours':'recipe_list');
      this.singleLabel = "#"+ this.translateService.instant(model.moD_RMT.rmT_Code==2?'flavour':'recipe');
      this.savedRecipeMessage = this.translateService.instant(model.moD_RMT.rmT_Code==2?'flavour_list_updated':'recipe_list_updated');
      this.model = model;
      this.loadTable(model,pair.flavList);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceFlavours.filter = filterValue.trim().toLowerCase();
  }

  get recipes(): FormArray {
    return this.form.get('recipes') as FormArray;
  }

  saveFlavourList() { 
    let tmpFlv:FlvFlavour[]=[];
    this.dataSourceFlavours.data.forEach(x=>{
      if(x.touched && !x.get('locked').value){
        tmpFlv.push({
          id:x.get('id').value,
          index:x.get('index').value,
          name:x.get('name').value,
          locked:x.get('locked').value,
          visible:x.get('visible').value
        })
      }
    })
    this.flavourService.postCustomList(this.machineSessioned.maC_Numero_Serie_MS,this.modCode,tmpFlv).subscribe
    (
      x=>{
        this.snackBarService.showSuccessBar(this.translateService.instant(this.savedRecipeMessage));
      },
      err=>{
        this.snackBarService.showErrorBar(err.message);
      }
    )
  }

  loadTable(model:ModElencoModelli,listFlavour:FlvFlavour[]){

   
    if(model.moD_DefaultRecipeID_Min!= null && model.moD_DefaultRecipeID_Max!= null )
      for(let i = model.moD_DefaultRecipeID_Min;i<=model.moD_DefaultRecipeID_Max; i++)
      {

        if(listFlavour.findIndex(x=>x.index==i)<0)
        {
          listFlavour.splice(i-1,0,{
            id:0,
            index:i,
            locked:true,
            visible:true,
            name:""
          })
        }
        else{
          let idx= listFlavour.findIndex(x=>x.index==i);
          listFlavour[idx].name = this.translateService.instant(listFlavour[idx].name)
        }
      }

      if(model.moD_CustomRecipeID_Min!= null && model.moD_CustomRecipeID_Max!= null )
      for(let i = model.moD_CustomRecipeID_Min;i<=model.moD_CustomRecipeID_Max; i++)
      {
        if(listFlavour.findIndex(x=>x.index==i)<0)
        {
          listFlavour.splice(i-1,0,{
            id:0,
            index:i,
            locked:false,
            visible:true,
            name:""
          })
        }
      }

      listFlavour = listFlavour.filter(flv => flv.visible );

      listFlavour = listFlavour.sort(function(a, b) {
        return a.index - b.index;
      });

      
      this.form = this._formBuilder.group({
        recipes: this._formBuilder.array([])
      });
      let arrayForm= new FormArray( listFlavour.map(FlvFlavour.asFormGroup));

      this.form.setControl('recipes', arrayForm);

      this.dataSourceFlavours = new MatTableDataSource(arrayForm.controls);
      this.dataSourceFlavours.paginator = this.paginator;
      
      this.dataSourceFlavours.sortingDataAccessor = (data: AbstractControl, sortHeaderId: string) => {
        const value: any = data.value[sortHeaderId];
        return typeof value === 'string' ? value.toLowerCase() : value;
      };
      this.dataSourceFlavours.sort = this.sort;

      const filterPredicate = this.dataSourceFlavours.filterPredicate;
      this.dataSourceFlavours.filterPredicate = (data: AbstractControl, filter) => {
        return filterPredicate.call(this.dataSourceFlavours, data.value, filter);
      }
  }

  reload()
  {
    this.flavourService.read(this.machineSessioned.maC_Numero_Serie_MS,this.modCode).subscribe(x=>{
      this.loadTable(this.model,x);
    });
  }

}
