<form [formGroup]="form" id="ngForm" (ngSubmit)="update()">
  <mat-form-field  > 
    <input matInput required type="text" formControlName="recipe_nameCtrl" placeholder="{{'name' | translate}}" >
    <mat-error *ngIf= "form.controls.recipe_nameCtrl.errors?.nameJustInUse">
      {{'name_just_in_use' | translate}}
    </mat-error>
    <mat-error *ngIf= "form.controls.recipe_nameCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
  </mat-form-field>
  <mat-form-field> 
    <mat-select required formControlName="recipe_typeCtrl"  placeholder="{{'type' | translate}}">
        <mat-option *ngFor="let type of recipeTypeList" [value]="type">{{ type | recipeTwist }}</mat-option>    
    </mat-select>
    <mat-error *ngIf= "form.controls.recipe_typeCtrl.errors?.required">
      {{'recipe_type_required' | translate}}
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="entityTemp.showConsistency"> 
    <input type="number"  [min]="ConsistencyMin" [max]="ConsistencyMax" step="1" required matInput formControlName="recipe_consistencyCtrl" placeholder="{{'consistency' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_consistencyCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_consistencyCtrl.errors?.max">
      {{'range_error_value' | translate : {min:ConsistencyMin, max: ConsistencyMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_consistencyCtrl.errors?.min">
      {{'range_error_value' | translate : { min: ConsistencyMin, max: ConsistencyMax } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="entityTemp.showTurbo"> 
    <input type="number"  [min]="TurboMin" [max]="TurboMax" step="1" required matInput formControlName="recipe_turboCtrl" placeholder="{{'turbo_s' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_turboCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_turboCtrl.errors?.max">
      {{'range_error_value' | translate : { min:TurboMin, max: TurboMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_turboCtrl.errors?.min">
      {{'range_error_value' | translate : { min: TurboMin, max: TurboMax } }}
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="entityTemp.showDuration" > 
    <input type="number" [min]="DurationMin" [max]="DurationMax" step="5" matInput required formControlName="recipe_durationCtrl" placeholder="{{'duration_s' | translate}}">
    <mat-error *ngIf= "form.controls.recipe_durationCtrl.errors?.required">
      {{'mandatory_value' | translate}}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_durationCtrl.errors?.max">
      {{'range_error_value' | translate : { min:DurationMin, max: DurationMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_durationCtrl.errors?.min">
      {{'range_error_value' | translate : { min:DurationMin, max: DurationMax } }}
    </mat-error>
    <mat-error *ngIf="form.controls.recipe_durationCtrl.errors?.oddment">
      {{'step_error_value' | translate : { step:5 } }}
    </mat-error>
  </mat-form-field>
</form>
