import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RecipeSoft, RecipeSoftHt } from 'src/app/objects/recipe';
import { AwsIotProviderService } from 'src/app/services/aws-iot-provider.service';
import { ModelService } from 'src/app/services/model.service';
import { SessionService } from 'src/app/services/session.service';
import { FrigomatTopicsPub } from 'src/app/utils/enums/frigomatTopics';
import { RecipeFormComponent } from '../recipe-form';

@Component({
  selector: 'app-recipe-soft-ht-form',
  templateUrl: './recipe-soft-ht-form.component.html',
  styleUrls: ['./recipe-soft-ht-form.component.scss']
})
export class RecipeSoftHtFormComponent extends RecipeFormComponent<RecipeSoftHt>   {

  @Input() entity:RecipeSoftHt;
  @Input() entityList:RecipeSoftHt[];
  @Output() protected onFormChange = new EventEmitter<any>();
  form: any;
 
  
  constructor(
    protected fb: FormBuilder,
    protected translateService:TranslateService,
    protected awsIotProviderService:AwsIotProviderService,
    protected sessionService:SessionService,
    protected modelService:ModelService
  ) { 
    super(fb,awsIotProviderService, sessionService, modelService);
  }

  ngOnInit() {
    this.isNew = this.entity==null;
    this.entityTemp = new RecipeSoftHt();
    Object.assign(this.entityTemp,this.entity);
  
    this.form = this.fb.group({
      recipe_nameCtrl: [this.isNew?'':this.entity.name, Validators.compose([Validators.required,  (c)=> this.newRecipeValidator(c,this.entityList,this.entity)])],
      recipe_consistencyCtrl: [this.isNew || this.entity.consistency==null?RecipeSoft.ConsistencyMin:this.entity.consistency,Validators.compose([Validators.required,Validators.max(RecipeSoft.ConsistencyMax),Validators.min(RecipeSoft.ConsistencyMin)])],
      recipe_conservationOffCtrl: [this.isNew || this.entity.conservationOff==null?RecipeSoft.OnOffMin:this.entity.conservationOff,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_conservationOnCtrl: [this.isNew || this.entity.conservationOn==null?RecipeSoft.OnOffMin:this.entity.conservationOn,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_productionOffCtrl: [this.isNew || this.entity.productionOff==null?RecipeSoft.OnOffMin:this.entity.productionOff,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_productionOnCtrl: [this.isNew || this.entity.productionOn==null?RecipeSoft.OnOffMin:this.entity.productionOn,Validators.compose([Validators.required,Validators.max(RecipeSoft.OnOffMax),Validators.min(RecipeSoft.OnOffMin)])],
      recipe_tankTempCtrl: [this.isNew || this.entity.tankTemperature==null?RecipeSoft.TankTempMin:this.entity.tankTemperature,Validators.compose([Validators.required,Validators.max(RecipeSoft.TankTempMax),Validators.min(RecipeSoft.TankTempMin)])],
      recipe_creativeCtrl: [this.isNew || this.entity.creative==null?false:this.entity.creative,Validators.required],
      recipe_htCycleTimeCtrl: [this.isNew || this.entity.heatCycleTime==null?"00:00":this.entity.heatCycleTime.replace(".",":"),Validators.required],
      recipe_htCycleMaxTimeIntervalCtrl: [this.isNew || this.entity.heatCyclesMaxTimeInterval==null?RecipeSoftHt.TimeIntervalMin:this.entity.heatCyclesMaxTimeInterval,Validators.compose([Validators.required,Validators.min(RecipeSoftHt.TimeIntervalMin),Validators.max(RecipeSoftHt.TimeIntervalMax)])],
      recipe_holdTimeStep2Ctrl: [this.isNew || this.entity.holdTimeStep2==null?RecipeSoftHt.HoldTimeDef:this.entity.holdTimeStep2,Validators.compose([Validators.required,Validators.max(RecipeSoftHt.HoldTimeMax),Validators.min(RecipeSoftHt.HoldTimeMin)])],
      recipe_maxTemperatureStep1Ctrl: [this.isNew || this.entity.maxTemperatureStep1==null?RecipeSoftHt.MaxTemperatureStep1Def:this.entity.maxTemperatureStep1,Validators.compose([Validators.required,Validators.max(RecipeSoftHt.MaxTemperatureStep1Max),Validators.min(RecipeSoftHt.MaxTemperatureStep1Min)])],
      recipe_minTemperatureStep3Ctrl: [this.isNew || this.entity.minTemperatureStep3==null?RecipeSoftHt.MinTemperatureStep3Def:this.entity.minTemperatureStep3,Validators.compose([Validators.required,Validators.max(RecipeSoftHt.MinTemperatureStep3Max),Validators.min(RecipeSoftHt.MinTemperatureStep3Min)])],
      recipe_tCoolingCtrl: [this.isNew || this.entity.tCooling==null?"":this.entity.tCooling],
      recipe_tHeatingCtrl: [this.isNew || this.entity.tHeating==null?"":this.entity.tHeating],
      recipe_tHoldingCtrl: [this.isNew || this.entity.tHolding==null?"":this.entity.tHolding],

      recipe_idCtrl: this.isNew?'':this.entity.id,
      });

      this.form.valueChanges
      .subscribe(() =>{ 
        this.onFormChange.emit(this.form.value)
      });
      this.onFormChange.emit(this.form.value);
  }

  public get OnOffMin(){
    return RecipeSoft.OnOffMin;
  }

  public get OnOffMax(){
    return RecipeSoft.OnOffMax;
  }

  public get TankTempMin(){
    return RecipeSoft.TankTempMin;
  }

  public get TankTempMax(){
    return RecipeSoft.TankTempMax;
  }
 
  public get TimeIntervalMin(){
    return RecipeSoftHt.TimeIntervalMin;
  }

  public get TimeIntervalMax(){
    return RecipeSoftHt.TimeIntervalMax;
  }

  public get MaxTemperatureStep1Min(){
    return RecipeSoftHt.MaxTemperatureStep1Min;
  }

  public get MaxTemperatureStep1Max(){
    return RecipeSoftHt.MaxTemperatureStep1Max;
  }

  public get MinTemperatureStep3Min(){
    return RecipeSoftHt.MinTemperatureStep3Min;
  }

  public get MinTemperatureStep3Max(){
    return RecipeSoftHt.MinTemperatureStep3Max;
  }

  public get HoldTimeMin(){
    return RecipeSoftHt.HoldTimeMin;
  }

  public get HoldTimeMax(){
    return RecipeSoftHt.HoldTimeMax;
  }

  update(isNew:boolean=false){
    try{
    let recipe = new RecipeSoftHt();
    recipe.id=isNew?undefined:this.form.get('recipe_idCtrl').value;
    recipe.name=this.form.get('recipe_nameCtrl').value;
    recipe.consistency= this.form.get('recipe_consistencyCtrl').value;
    recipe.conservationOff= this.form.get('recipe_conservationOffCtrl').value;
    recipe.conservationOn= this.form.get('recipe_conservationOnCtrl').value;
    recipe.productionOff= this.form.get('recipe_productionOffCtrl').value;
    recipe.productionOn= this.form.get('recipe_productionOnCtrl').value;
    recipe.creative= this.form.get('recipe_creativeCtrl').value;
    recipe.tankTemperature= this.form.get('recipe_tankTempCtrl').value;
    recipe.heatCycleTime = this.form.get('recipe_htCycleTimeCtrl').value.replace(":",".");;
    recipe.heatCyclesMaxTimeInterval = ""+this.form.get('recipe_htCycleMaxTimeIntervalCtrl').value;
    recipe.holdTimeStep2= ""+this.form.get('recipe_holdTimeStep2Ctrl').value;
    recipe.maxTemperatureStep1 = ""+this.form.get('recipe_maxTemperatureStep1Ctrl').value;
    recipe.minTemperatureStep3 = ""+this.form.get('recipe_minTemperatureStep3Ctrl').value;
    recipe.tCooling = this.form.get('recipe_tCoolingCtrl').value;
    recipe.tHeating = this.form.get('recipe_tHeatingCtrl').value;
    recipe.tHolding = this.form.get('recipe_tHoldingCtrl').value;
  
    this.publish(isNew?FrigomatTopicsPub.addRecipe:FrigomatTopicsPub.editRecipe,recipe);
      return true;
    }
    catch{
      return false;
    }
  }

  delete(){
    try{
    let recipe = new RecipeSoftHt();
    recipe.id=this.form.get('recipe_idCtrl').value;
    recipe.name=this.form.get('recipe_nameCtrl').value;
    recipe.consistency= this.form.get('recipe_consistencyCtrl').value;
    recipe.conservationOff= this.form.get('recipe_conservationOffCtrl').value;
    recipe.conservationOn= this.form.get('recipe_conservationOffCtrl').value;
    recipe.productionOff= this.form.get('recipe_productionOffCtrl').value;
    recipe.productionOn= this.form.get('recipe_productionOnCtrl').value;
    recipe.creative= this.form.get('recipe_tankTempCtrl').value;
    recipe.tankTemperature= this.form.get('recipe_creativeCtrl').value;
    recipe.heatCycleTime = this.form.get('recipe_htCycleTimeCtrl').value.replace(":",".");
    recipe.heatCyclesMaxTimeInterval = ""+this.form.get('recipe_htCycleMaxTimeIntervalCtrl').value;
    recipe.holdTimeStep2= ""+this.form.get('recipe_holdTimeStep2Ctrl').value;
    recipe.maxTemperatureStep1 = ""+this.form.get('recipe_maxTemperatureStep1Ctrl').value;
    recipe.minTemperatureStep3 = ""+this.form.get('recipe_minTemperatureStep3Ctrl').value;
    recipe.tCooling = this.form.get('recipe_tCoolingCtrl').value;
    recipe.tHeating = this.form.get('recipe_tHeatingCtrl').value;
    recipe.tHolding = this.form.get('recipe_tHoldingCtrl').value;
  
    this.publish(FrigomatTopicsPub.deleteRecipe,recipe);
      return true;
    }
    catch{
      return false;
    }
  }

}
