<div class="main-container">
  <div class="inside-main-container">
    <mat-card class="mat-elevation-z8">
      <div class="singleunit">
        <div class="inside-singleunit" *ngIf="machineSessioned.maC_LastUpdate">
          <mat-label>
            <b>{{ "last_communication" | translate }}</b>
            <br />
            <small>
              {{ dateLastCom | date : "dd/MM/yy HH:mm:ss" }}
              <div *ngIf="!machineSessioned.maC_LastUpdate">
                {{ "no_last_communication" | translate }}
              </div>
            </small>
          </mat-label>
        </div>
        <!-- iccid  -->
        <div class="inside-singleunit"
          *ngIf="checkICCIDVisible(machineSessioned.rgS_Registrazione.rgS_ICCID)"
        >
          <mat-label>
            <b>ICCID</b>
            <br />
            <small>
              {{ machineSessioned.rgS_Registrazione.rgS_ICCID }}
            </small>
          </mat-label>
        </div>
      </div>
      <!-- STAMPO VERSIONI PER TUTTE LE MACCHINE TRANNE LA GX, LA GX E' SOTTO -->
      <div class="singleunit"
        *ngIf="machineSessioned.maC_ModelloSx !== 2"
      >
        <!-- versione software  -->
        <div class="inside-singleunit" *ngIf="checkPrintVersion(machineSessioned.maC_VersSwInt)" >
          <mat-label>
            <b>{{ "version" | translate }} Software</b>
            <br />
            <small>
              {{ machineSessioned.maC_VersSwInt | swVersion }}
            </small>
          </mat-label>
        </div>
        <!-- quadro Sx e video Sx  -->
        <div class="inside-singleunit"
          *ngIf="checkPrintVersion(machineSessioned.maC_VersSwQSx)"
        >
          <mat-label>
            <b
              >{{ "version" | translate }} Software
              <label *ngIf="machineSessioned.maC_ModelloSx === 8">
                {{ "board" | translate }}
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloSx !== 6 &&
                    machineSessioned.maC_ModelloSx !== 7 &&
                    machineSessioned.maC_ModelloSx !== 8 &&
                    machineSessioned.maC_ModelloSx !== 11 &&
                    machineSessioned.maC_ModelloSx !== 12
                  "
                >
                  1
                </label>
                <!-- controllo solo per le combinate, sottostante  -->
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloDx !== null &&
                    machineSessioned.maC_ModelloDx !== 0 &&
                    machineSessioned.maC_ModelloDx !==
                      machineSessioned.maC_ModelloSx
                  "
                >
                  1
                </label>
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx === 7">
                {{ "board" | translate }} 1
              </label>
              <label
                *ngIf="
                  machineSessioned.maC_ModelloSx !== 7 &&
                  machineSessioned.maC_ModelloSx !== 8
                "
              >
                {{ "board" | translate }}
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloSx !== 6 &&
                    machineSessioned.maC_ModelloSx !== 7 &&
                    machineSessioned.maC_ModelloSx !== 8 &&
                    machineSessioned.maC_ModelloSx !== 11 &&
                    machineSessioned.maC_ModelloSx !== 12
                  "
                >
                  1
                </label>
                <!-- controllo solo per le combinate, sottostante  -->
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloDx !== null &&
                    machineSessioned.maC_ModelloDx !== 0 &&
                    machineSessioned.maC_ModelloDx !==
                      machineSessioned.maC_ModelloSx
                  "
                >
                  1
                </label>
              </label>
            </b>
            <br />
            <small>
              {{ machineSessioned.maC_VersSwQSx | swVersion }}
            </small>
          </mat-label>
        </div>

        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwVSx) &&
            machineSessioned.maC_VersSwVSx
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">
                {{ "video" | translate }}
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloSx !== 6 &&
                    machineSessioned.maC_ModelloSx !== 7 &&
                    machineSessioned.maC_ModelloSx !== 8 &&
                    machineSessioned.maC_ModelloSx !== 11 &&
                    machineSessioned.maC_ModelloSx !== 12
                  "
                >
                  1
                </label>
                <!-- controllo solo per le combinate, sottostante  -->
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloDx !== null &&
                    machineSessioned.maC_ModelloDx !== 0 &&
                    machineSessioned.maC_ModelloDx !==
                      machineSessioned.maC_ModelloSx
                  "
                >
                  1
                </label>
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">
                {{ "video" | translate }}
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloSx !== 6 &&
                    machineSessioned.maC_ModelloSx !== 7 &&
                    machineSessioned.maC_ModelloSx !== 8 &&
                    machineSessioned.maC_ModelloSx !== 11 &&
                    machineSessioned.maC_ModelloSx !== 12
                  "
                >
                  1
                </label>
                <!-- controllo solo per le combinate, sottostante  -->
                <label
                  *ngIf="
                    machineSessioned.maC_ModelloDx !== null &&
                    machineSessioned.maC_ModelloDx !== 0 &&
                    machineSessioned.maC_ModelloDx !==
                      machineSessioned.maC_ModelloSx
                  "
                >
                  1
                </label>
              </label>
            </b>
            <br />
            <small>
              {{ machineSessioned.maC_VersSwVSx | swVersion }}
            </small>
          </mat-label>
        </div>
        <!-- versione video e quadro dx -->

        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwQDx) &&
            machineSessioned.maC_VersSwQDx
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">
                {{ "board" | translate }} 2
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">
                {{ "board" | translate }} 2
              </label>
            </b>
            <br />
            <small>
              {{ machineSessioned.maC_VersSwQDx | swVersion }}
            </small>
          </mat-label>
        </div>

        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwVDx) &&
            machineSessioned.maC_VersSwVDx
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">
                {{ "video" | translate }} 2
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">
                {{ "video" | translate }} 2
              </label>
            </b>
            <br />
            <small>
              {{ machineSessioned.maC_VersSwVDx | swVersion }}
            </small>
          </mat-label>
        </div>
        <!-- versione quadro e video Centro  -->

        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwQCen) &&
            machineSessioned.maC_VersSwQCen
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software {{ "board" | translate }} 3
            </b>
            <br />
            <small *ngIf="machineSessioned">
              {{ machineSessioned.maC_VersSwQCen | swVersion }}
            </small>
          </mat-label>
        </div>

        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwVCen) &&
            machineSessioned.maC_VersSwVCen
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software {{ "video" | translate }} 3
            </b>
            <br />
            <small>{{ machineSessioned.maC_VersSwVCen | swVersion }}</small>
          </mat-label>
        </div>
      </div>
      <!-- SOLO MACCHINE GX -->
      <div class="singleunit"
        *ngIf="machineSessioned.maC_ModelloSx === 2"
      >
        <!-- versione software  -->
        <div class="inside-singleunit"
          *ngIf="checkPrintVersion(machineSessioned.maC_VersSwInt)"
        >
          <mat-label>
            <b>{{ "version" | translate }} Software </b>
            <br />
            <small>{{ machineSessioned.maC_VersSwInt | swVersion }}</small>
          </mat-label>
        </div>

        <!-- quadro Sx e video Sx  -->
        <div class="inside-singleunit"
          *ngIf="checkPrintVersion(machineSessioned.maC_VersSwQSx)"
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software
              <label
                *ngIf="
                  machineSessioned.maC_ModelloSx === 2 ||
                  machineSessioned.maC_ModelloSx === 8
                "
              >
                {{ "board" | translate }} 1
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx === 7">
                {{ "board" | translate }}
              </label>
              <label
                *ngIf="
                  machineSessioned.maC_ModelloSx !== 2 &&
                  machineSessioned.maC_ModelloSx !== 7 &&
                  machineSessioned.maC_ModelloSx !== 8
                "
              >
                {{ "board" | translate }} 1
              </label>
            </b>
            <br />
            <small>
              {{ machineSessioned.maC_VersSwQSx | swVersion }}
            </small>
          </mat-label>
        </div>

        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwVSx) &&
            machineSessioned.maC_VersSwVSx
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">
                {{ "board" | translate }} 2
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">
                {{ "video" | translate }} 1
              </label>
            </b>
            <br />
            <small>{{ machineSessioned.maC_VersSwVSx | swVersion }}</small>
          </mat-label>
        </div>

        <!-- versione video e quadro dx -->

        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwQDx) &&
            machineSessioned.maC_VersSwQDx
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">
                {{ "board" | translate }} 3
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">
                {{ "board" | translate }} 2
              </label>
            </b>
            <br />
            <small>
              {{ machineSessioned.maC_VersSwQDx | swVersion }}
            </small>
          </mat-label>
        </div>
        <div class="inside-singleunit"
          *ngIf="
            checkPrintVersion(machineSessioned.maC_VersSwVDx) &&
            machineSessioned.maC_VersSwVDx
          "
        >
          <mat-label>
            <b>
              {{ "version" | translate }} Software
              <label *ngIf="machineSessioned.maC_ModelloSx === 2">
                {{ "board" | translate }} 4
              </label>
              <label *ngIf="machineSessioned.maC_ModelloSx !== 2">
                {{ "video" | translate }} 2
              </label>
            </b>
            <br />
            <small>{{ machineSessioned.maC_VersSwVDx | swVersion }}</small>
          </mat-label>
        </div>
      </div>
      <!-- pagamento  -->
      <div class="singleunit">
        <div class="inside-singleunit"
          *ngIf="
            (machineSessioned.maC_Pagamento && userLogged.utE_Livello === 1) ||
            userLogged.utE_Livello === 0
          "
        >
          <mat-label>
            <b>
              {{ "payment_state" | translate }}
            </b>
            <br />
          </mat-label>
          <mat-icon style="font-size: 2em; text-align: center;"
              [ngStyle]="{ 'color' : machineSessioned.maC_Pagamento | payments }">circle</mat-icon> 
      
        </div>
      </div>
    </mat-card>
  </div>
</div>
