<h1 mat-dialog-title>{{'delete' | translate}}</h1>
<div mat-dialog-content>
  {{ 'are_you_sure' | translate }}
</div>
<div mat-dialog-actions>
  <p>
    <button mat-button (click)="onNoClick()">{{ 'no' | translate }}</button>
    <button mat-raised-button color="primary" (click)="delete()" cdkFocusInitial>{{ 'yes_sure' | translate }}</button>
  </p>
</div>
