import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '../enums/userRole';


@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {
  constructor(public translateService: TranslateService) {
  }

  transform(level: number): any {
    switch(level){
      case UserRole.Admin:
        return this.translateService.instant('administrator');
      case UserRole.Customer:
        return this.translateService.instant('customer');
      case UserRole.Dealer:
        return this.translateService.instant('dealer');
      case UserRole.Technician:
        return this.translateService.instant('technician');              
    }
  }
}

